<script lang="ts" setup>
import type { IGrain, ISupport } from '@/assets/ts/types/TrainingTypes'
import { computed, ref } from 'vue'
import { default as PButton } from 'primevue/button'

// IMPORTS
const emits = defineEmits(['start-support'])

// PROPS
const props = defineProps<{
  grain: IGrain
  currentSupport: number
  support: ISupport
}>()

// DATA & REFS
const showSupport = ref(false)

// COMPUTED
const unitPosition = computed(() => {
  if (props.currentSupport === props.support.order) {
    return 'left: 50%; transform: translate(-50%, -50%);'
  }

  if (props.currentSupport > props.support.order) {
    return `left: calc(50% - ${(props.currentSupport - props.support.order) * 100}vw)`
  }

  if (props.currentSupport < props.support.order) {
    return `left: calc(50% + ${(props.support.order - props.currentSupport) * 100}vw)`
  }
  return `left: calc(50% + ${(props.support.order - props.currentSupport) * 100}vw)`
})

// METHODS
function startSupport() {
  showSupport.value = true
  emits('start-support')
}
</script>

<template>
  <div :class="$style['Grain-Wrapper']" :style="unitPosition">
    <div :class="$style['Grain-Body']">
      <p-button
        v-if="!showSupport"
        label="C'est parti !"
        icon="pi pi-play"
        :class="$style['Button-Show-Support']"
        size="large"
        iconPos="right"
        @click="startSupport()"
        rounded
      />
      <iframe
        v-else-if="showSupport && support.type !== 'END'"
        width="100%"
        height="100%"
        :src="support.url"
        title="Support Naboo"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </div>
</template>

<style module lang="scss">
.Grain-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;

  .Grain-Body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: url('@/assets/images/backgroundAssets/sessions/Dark_2.svg');
    background-size: cover;

    .Button-Show-Support {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>
