<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'
import MascotteIntro from '@/assets/images/mascottes/MASCOTTE_Accueil.png'

import { onMounted, ref, useCssModule } from 'vue'
import EHelpButton from '@/components/elements/buttons/EHelpButton.vue'

const expandedHeader = ref(true)
const style = useCssModule()
const step = ref(1)
const button = ref()

const emit = defineEmits(['start'])

onMounted(() => {
  // after 1s the mascotte appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuiz-Mascotte']}`)
      ?.classList.remove(style['VQuiz-Mascotte--hidden'])
  }, 500)

  // after 1.5s the intro appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuiz-Intro']}`)
      ?.classList.remove(style['VQuiz-Intro--hidden'])
  }, 1000)

  // after 2s the button appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuizz-Button']}`)
      ?.classList.remove(style['VQuizz-Button--hidden'])
  }, 1500)
})

function processStep() {
  if (step.value === 1) {
    step.value = 2
    return
  }

  if (step.value === 2) {
    emit('start')
    return
  }
}
</script>

<template>
  <div
    class="w-full flex flex-col items-center justify-center flex-grow relative max-w-screen-md mx-auto"
  >
    <div
      class="bg-white rounded-border shadow-lg p-3 relative text-primary"
      :class="[$style['VQuiz-Intro'], $style['VQuiz-Intro--hidden']]"
    >
      <template v-if="step === 1">
        <p class="text-primary mb-3">
          Tu vas répondre à des questions sur <span class="font-bold">5 grandes thématiques</span>,
          avec différents types de questions : des vrais ou faux, des questions à choix multiples,
          et bien d’autres !
        </p>
        <p class="text-primary">Chaque thématique te donnera accès à une mission sur ce sujet.</p>
      </template>

      <template v-else>
        <p class="text-primary">Si à tout moment tu ne sais pas comment fonctionne l’activité,</p>

        <p class="text-primary items-center inline-flex gap-2 font-bold">
          clique sur <e-help-button />
        </p>
        <p class="heading medium font-bold mt-4">Tu es prêt ?</p>
      </template>
    </div>

    <p-image
      :class="[$style['VQuiz-Mascotte'], $style['VQuiz-Mascotte--hidden']]"
      imageClass="h-full"
      :src="MascotteIntro"
    />

    <div class="absolute bottom-10">
      <p-button
        ref="button"
        :class="[$style['VQuizz-Button'], $style['VQuizz-Button--hidden']]"
        rounded
        :label="step === 1 ? 'J\'ai compris !' : 'C\'est parti !'"
        icon="pi pi-play"
        iconPos="right"
        severity="light"
        @click="() => processStep()"
      />
    </div>
  </div>
</template>

<style module>
.VQuiz-Intro {
  max-width: 20rem;
  text-align: center;
  position: relative;
  transition: all 0.5s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid white;
  }

  &.VQuiz-Intro--hidden {
    transform: translateY(1000px);
  }
}

.VQuiz-Mascotte {
  transition: all 0.5s ease-in-out;
  max-height: 50%;

  &.VQuiz-Mascotte--hidden {
    transform: translateY(200%);
  }
}

.VQuizz-Button {
  transition: all 0.5s ease-in-out;

  &.VQuizz-Button--hidden {
    transform: translateY(1000px);
  }
}
</style>
