<script setup lang="ts">
import { default as PRadioButton } from 'primevue/radiobutton'
import { default as PButton } from 'primevue/button'
import { computed, type Ref, ref } from 'vue'
import type { IMCQAnswer, IUCQAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer'])

const props = defineProps<{
  answersContent: IUCQAnswer[]
}>()

const selectedUserAnswer: Ref<string> = ref('')

const getCorrectAnswer = computed(() => {
  return props.answersContent.filter((answer) => answer.isCorrect)[0].label
})

const handleAnswer = () => {
  let userAnswer = {} as IUserAnswer

  userAnswer.answers = [selectedUserAnswer.value]
  userAnswer.isCorrect = getCorrectAnswer.value === selectedUserAnswer.value

  emit('answer', userAnswer)
}
</script>

<template>
  <form class="grid grid-cols-1 w-full gap-4 auto-rows-[minmax(min-content,1fr)]">
    <div v-for="(answer, i) in answersContent" class="flex items-center gap-2" :key="'answer_' + i">
      <p-radio-button :value="answer.label" :inputId="answer.label" v-model="selectedUserAnswer" />
      <label
        class="text bg-surface-blue rounded-lg p-4 flex-grow h-full flex items-center min-h-24"
        :for="answer.label"
        >{{ answer.label }}</label
      >
    </div>
  </form>

  <div class="fixed bottom-0 w-full flex justify-center p-4 pt-8" :class="$style.QuizFooter">
    <p-button
      label="Valider"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :outlined="selectedUserAnswer.length === 0"
      :disabled="selectedUserAnswer.length === 0"
    />
  </div>
</template>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, var(--surface-blue-500) 0%, var(--surface-blue-500) 100%);
}
</style>
