<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { onMounted, useCssModule } from 'vue'
import EBackButton from '@/components/elements/buttons/EBackButton.vue'
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'

const style = useCssModule()

defineProps<{
  title: string
  expanded: boolean
}>()
</script>

<template>
  <div
    class="flex w-full items-center px-4 py-2"
    :class="[$style['QuizHeader'], { [$style['QuizHeader--expanded']]: expanded }]"
  >
    <h4 class="grow text-center">Test d'entrée</h4>
    <e-navigation-button icon="Close" @click="$router.push({ name: 'home' })" />
  </div>
</template>

<style lang="scss" module>
.QuizHeader {
  background-color: var(--primary-dark-blue);
  color: var(--variant-white) !important;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  height: 5rem;
  z-index: 10;
  position: relative;

  &--expanded {
    height: 100%;
    max-height: 100%;

    .p-button-rounded {
      opacity: 0;
    }
  }
}

.p-button-rounded {
  width: 1.1rem !important;
  height: 1.1rem !important;
  background-color: var(--variant-green) !important;
  color: var(--primary-dark-blue) !important;
  border: none !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  position: relative;
  outline: none !important;
  opacity: 1;

  > span {
    font-size: 0.6rem !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0.3rem);
    height: calc(100% + 0.3rem);
    border: 0.2rem solid var(--variant-green);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
