import type { IGrain, ISession } from '@/assets/ts/types/TrainingTypes'
import type { IRating } from '@/assets/ts/types/ratingTypes'

export class TrainingInputDto {
  profile: { userName: string; identifier: string }
  trainingId: number
  unitId: number
  registrationCtx: string
  userAgent: string
  appType: string
  verbId: VerbsIdEnum
  verbDisplay: VerbsDisplayEnum

  constructor(obj: TrainingInputDto) {
    this.profile = obj.profile
    this.trainingId = obj.trainingId
    this.unitId = obj.unitId
    this.registrationCtx = obj.registrationCtx
    this.userAgent = obj.userAgent
    this.appType = obj.appType
    this.verbId = obj.verbId
    this.verbDisplay = obj.verbDisplay
  }
}

export class TrainingSessionInputDto extends TrainingInputDto {
  sessionId: number
  sessionTitle: string

  constructor(obj: TrainingSessionInputDto) {
    super(obj)
    this.sessionId = obj.sessionId
    this.sessionTitle = obj.sessionTitle
  }
}

export class TrainingGrainInputDto extends TrainingInputDto {
  grainId: number
  grainTitle: string
  parent: ISession
  points: number

  constructor(obj: TrainingGrainInputDto) {
    super(obj)
    this.grainId = obj.grainId
    this.grainTitle = obj.grainTitle
    this.parent = obj.parent
    this.points = obj.points
  }
}

export class TrainingSupportInputDto extends TrainingInputDto {
  supportId: number
  supportTitle: string
  parent: IGrain

  constructor(obj: TrainingSupportInputDto) {
    super(obj)
    this.supportId = obj.supportId
    this.supportTitle = obj.supportTitle
    this.parent = obj.parent
  }
}

export class TrainingRatingInputDto extends TrainingInputDto {
  ratings: IRating[]
  review: string
  parent: IGrain | ISession
  grainId: number
  grainTitle: string

  constructor(obj: TrainingRatingInputDto) {
    super(obj)
    this.ratings = obj.ratings
    this.review = obj.review
    this.parent = obj.parent
    this.grainId = obj.grainId
    this.grainTitle = obj.grainTitle
  }
}

export enum DefinitionObjectType {
  Session = 'https://naboo-edu.fr/activities/sessions',
  Grain = 'https://naboo-edu.fr/activities/grains',
  Support = 'https://naboo-edu.fr/activities/supports'
  // Ajoutez d'autres types d'objets de définition au besoin
}

export enum VerbsIdEnum {
  Started = 'http://adlnet.gov/expapi/verbs/started',
  Completed = 'http://adlnet.gov/expapi/verbs/completed',
  Reviewed = 'http://adlnet.gov/expapi/verbs/reviewed'
}

export enum VerbsDisplayEnum {
  Started = 'started',
  Completed = 'completed',
  Reviewed = 'reviewed'
}

export interface IActor {
  name: string
  account: {
    homePage: string
    name: string
  }
}

export interface IVerb {
  id: VerbsIdEnum
  display: { 'en-US': VerbsDisplayEnum }
}

interface IObjectDefinition {
  name: { 'fr-FR': string }
  type: DefinitionObjectType
}

export interface IObject {
  objectType: 'Activity'
  id: string
  definition: IObjectDefinition
  extensions: {
    'https://naboo-edu.fr/activities/trainings': number
    'https://naboo-edu.fr/activities/units': number
  }
}

export interface IContextExtensions {
  'https://naboo-edu.fr/extensions/userAgent': string
  'https://naboo-edu.fr/extensions/appType': string
  'https://naboo-edu.fr/extensions/appVersion': typeof __APP_VERSION__
}

export interface IParent {
  objectType: string
  id: string
  definition: {
    name: { 'en-US': string }
  }
}

export interface IContext {
  registration: string
  platform: string
  extensions: IContextExtensions
  parent: IParent
}

export interface IResult {
  score: {
    raw: number
    max: number
    min?: number
    scaled?: number
  }
  success: boolean
  completion: boolean
  extensions: {
    'https://naboo-edu.fr/extensions/feedback': {
      questions: IExtensionsQuestions[]
      comment: string
    }
  }
}

export interface IExtensionsQuestions {
  id: string
  text: string
  min: number
  max: number
  raw: number
}

export interface ITrainingPayload {
  id: string
  actor: IActor
  verb: IVerb
  object: IObject
  context: IContext
  timestamp: string
  result?: IResult
}
