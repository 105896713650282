import { v4 as uuidv4 } from 'uuid'
import type {
  IActivityObject,
  IActor,
  IContext,
  IExitedApplication,
  ILaunchedApplication,
  IResult,
  IVerb,
  IXAPIStatement,
  UUID
} from '@/assets/ts/types/xAPI/xAPI'
import type {
  ICompletedLearningCourse,
  IInitializedLearningCourse
} from '@/assets/ts/types/xAPI/learning'
import type {
  IAnsweredMultipleChoiceQuestion,
  IAnsweredSingleChoiceQuestion,
  IAnsweredTrueFalseQuestion,
  IAttemptedQuiz,
  ICompletedQuiz
} from '@/assets/ts/types/xAPI/quiz'

export class xAPIMessageBuilder {
  private static generateBaseMessage(
    actor: IActor,
    verb: IVerb,
    object: IActivityObject,
    context: IContext,
    result?: IResult
  ): IXAPIStatement {
    return {
      id: uuidv4(),
      actor,
      verb,
      object,
      context,
      result,
      timestamp: new Date().toISOString()
    }
  }

  private static createVerb(id: string, display: string): IVerb {
    return {
      id,
      display: { 'en-US': display }
    }
  }

  private static createActor(actorUUID: UUID): IActor {
    return {
      objectType: 'Agent',
      account: {
        homePage: 'https://naboo-edu.fr',
        name: actorUUID
      }
    }
  }

  private static createContext(
    contextRegistrationUUID: UUID,
    platform: 'web' | 'pwa',
    language: string,
    extensions?: { [key: string]: any },
    contextActivities?: any
  ): IContext {
    return {
      registration: contextRegistrationUUID,
      platform,
      language,
      extensions,
      contextActivities
    }
  }

  private static createObject(
    id: string,
    name: { [key: string]: string },
    type?: string,
    interactionType?: string,
    correctResponsesPattern?: string[],
    choices?: Array<{ id: string; description: { [key: string]: string } }>
  ): IActivityObject {
    return {
      objectType: 'Activity',
      id,
      definition: {
        name,
        type,
        interactionType,
        correctResponsesPattern: [correctResponsesPattern?.join('[,]') as string],
        choices
      }
    }
  }

  static createLaunchedApplicationMessage(
    actor: IActor,
    contextRegistrationUUID: UUID
  ): ILaunchedApplication {
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/launched', 'launched')
    const object = this.createObject('https://naboo-edu.fr/activities/application', {
      'en-US': 'Application'
    })
    const context = this.createContext(contextRegistrationUUID, 'web', 'en-US')

    return this.generateBaseMessage(actor, verb, object, context) as ILaunchedApplication
  }

  static createExitedApplicationMessage(
    actor: IActor,
    contextRegistrationUUID: UUID,
    platform: 'web' | 'pwa',
    language: string
  ): IExitedApplication {
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/exited', 'Exited')
    const object = this.createObject('https://naboo-edu.fr/activities/application', {
      'en-US': 'Application'
    })
    const context = this.createContext(contextRegistrationUUID, platform, language)

    return this.generateBaseMessage(actor, verb, object, context) as IExitedApplication
  }

  static createInitializedLearningCourseMessage(
    actor: IActor,
    contextRegistrationUUID: UUID,
    courseId: string,
    platform: 'web' | 'pwa',
    language: string
  ): IInitializedLearningCourse {
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/initialized', 'Initialized')
    const object = this.createObject(`https://naboo-edu.fr/activities/courses/${courseId}`, {
      'en-US': `Learning Course ${courseId}`
    })
    const context = this.createContext(contextRegistrationUUID, platform, language)

    return this.generateBaseMessage(actor, verb, object, context) as IInitializedLearningCourse
  }

  static createCompletedLearningCourseMessage(
    actor: IActor,
    contextRegistrationUUID: UUID,
    courseId: string,
    platform: 'web' | 'pwa',
    language: string
  ): ICompletedLearningCourse {
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/completed', 'Completed')
    const object = this.createObject(`https://naboo-edu.fr/activities/courses/${courseId}`, {
      'en-US': `Learning Course ${courseId}`
    })
    const context = this.createContext(contextRegistrationUUID, platform, language)

    return this.generateBaseMessage(actor, verb, object, context) as ICompletedLearningCourse
  }

  static createAttemptedQuizMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId?: string
    quizId: string
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
  }): IAttemptedQuiz {
    const actor = this.createActor(input.actorUUID)
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/attempted', 'Attempted')
    const object = this.createObject(
      `https://naboo-edu.fr/activities/courses/${input.courseId}/quizzes/${input.quizId}`,
      { 'en-US': `Quiz ${input.quizId}` },
      'http://adlnet.gov/expapi/activities/assessment'
    )
    const context = this.createContext(
      input.contextRegistrationUUID,
      input.platform,
      input.language,
      { 'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID },
      {
        parent: [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}`,
            definition: { name: { 'en-US': `Learning Module ${input.moduleId}` } }
          }
        ]
      }
    )

    return this.generateBaseMessage(actor, verb, object, context) as IAttemptedQuiz
  }

  static createAnsweredQuestionMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    duration: string
    moduleId: string | null
    grainId: string | null
    quizId: string
    questionId: string
    questionLabel: string
    response: string[]
    success: boolean
    score: number
    target?: any[]
    source?: any[]
    maxScore: number
    minScore: number
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
    interactionType: 'true-false' | 'choice' | 'matching' | 'sequencing'
    correctResponsesPattern: string[]
    choices?: Array<{ id: string; description: { [key: string]: string } }>
  }): IAnsweredTrueFalseQuestion | IAnsweredSingleChoiceQuestion | IAnsweredMultipleChoiceQuestion {
    const actor = this.createActor(input.actorUUID)
    const verb = this.createVerb('http://adlnet.gov/expapi/verbs/answered', 'Answered')

    const object = this.createObject(
      `https://naboo-edu.fr/activities/courses/${input.courseId}/quizzes/${input.quizId}/questions/${input.questionId}`,
      { 'fr-FR': input.questionLabel },
      'http://adlnet.gov/expapi/activities/cmi.interaction',
      input.interactionType,
      input.correctResponsesPattern,
      input.choices
    )

    const minScore = input.minScore
    const maxScore = input.maxScore

    const result: IResult = {
      success: input.success,
      response: input.response.join('[,]'),
      score: {
        raw: input.score,
        min: minScore,
        max: input.maxScore,
        scaled: (input.score - minScore) / (maxScore - minScore)
      },
      duration: input.duration
    }

    const context = this.createContext(
      input.contextRegistrationUUID,
      input.platform,
      input.language,
      { 'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID },
      {
        parent: [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}/quizzes/${input.quizId}`,
            definition: { name: { 'en-US': `Quiz ${input.quizId}` } }
          }
        ]
      }
    )

    return this.generateBaseMessage(actor, verb, object, context, result) as
      | IAnsweredTrueFalseQuestion
      | IAnsweredSingleChoiceQuestion
      | IAnsweredMultipleChoiceQuestion
  }

  // COMPLETE quizz method
  static createCompletedQuizMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId: string | null
    quizId: string
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
    score: number
    maxScore: number
    minScore: number
    duration: string
  }): ICompletedQuiz {
    const actor: IActor = {
      objectType: 'Agent',
      account: {
        homePage: 'https://naboo-edu.fr',
        name: input.actorUUID
      }
    }

    const verb: IVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }

    const object: IActivityObject = {
      objectType: 'Activity',
      id: `https://naboo-edu.fr/activities/courses/${input.courseId}/quizzes/${input.quizId}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/assessment',
        name: { 'en-US': `Quiz ${input.quizId}` }
      }
    }

    const minScore = input.minScore
    const maxScore = input.maxScore

    const result: IResult = {
      completion: true,
      success: input.score >= input.maxScore * 0.7, // Assuming 70% is the passing score, adjust as needed
      score: {
        scaled: (input.score - minScore) / (maxScore - minScore),
        raw: input.score,
        min: minScore,
        max: input.maxScore
      },
      duration: input.duration // Example duration, replace with actual if needed
    }

    const context: IContext = {
      registration: input.contextRegistrationUUID,
      platform: input.platform,
      language: input.language,
      extensions: {
        'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID
      },
      contextActivities: {
        parent: [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}`,
            definition: {
              name: { 'en-US': `Learning Course ${input.courseId}` }
            }
          }
        ]
      }
    }

    return this.generateBaseMessage(actor, verb, object, context, result) as ICompletedQuiz
  }

  // More methods for other scenarios can be added here...
}
