<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'
import { computed, onMounted, ref } from 'vue'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import MissionBlock from '@/views/logbook/blocks/MissionBlock.vue'
import TestBlock from '@/views/logbook/blocks/TestBlock.vue'
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import { useProgressStore } from '@/stores/progress'
import MProgress from '@/components/modules/progress/MProgress.vue'
import type { IProgressMission, IProgressQuiz } from '@/assets/ts/types/progress/Progress'

const { firstName, userPicture, toggleProfile } = useProfileStore()
const { globalProgress, testsToPass, missionsInProgress } = storeToRefs(useProgressStore())

const loading = ref(true)
const showProfile = ref(false)

onMounted(() => {
  loading.value = false
})

const isMissionModuleHasDiagToDo = (mission: IProgressMission) => {
  return !!testsToPass.value.find((test: IProgressQuiz) => {
    if (test.learningCourseId === mission.learningCourseId) {
      return true
    }
  })
}

const missionsToDisplay = computed(() => {
  const missions: IProgressMission[] = []

  missionsInProgress.value.forEach((mission: IProgressMission) => {
    if (!isMissionModuleHasDiagToDo(mission)) {
      missions.push(mission)
    }
  })

  return missions
})
</script>

<template>
  <b-orbits />
  <div
    :class="$style.VLogbook"
    class="w-full h-full px-4 max-w-screen-sm mx-auto overflow-auto pb-4"
    v-if="!loading"
  >
    <div class="flex justify-between items-center my-4">
      <div class="text-white text-3xl font-bold font-secondary">
        Hello <span class="font-primary extra-light">{{ firstName }}</span> !
      </div>
      <p-button text class="p-0" @click="toggleProfile">
        <p-image
          class="border-2 border-white rounded-full"
          style="width: 2.5rem; height: 2.5rem"
          imageClass="w-full h-full"
          :src="userPicture"
          alt="WelcomeProgress"
        />
      </p-button>
    </div>

    <m-progress
      :completed="globalProgress.completed"
      :inProgress="globalProgress.inProgress"
      :percentage="globalProgress.progress"
    />

    <div class="flex flex-col justify-start gap-4 mt-3">
      <test-block v-for="(test, i) in testsToPass" :key="`test-d-entree-${i}`" :quiz="test" />
      <h2 class="brand-heading medium" v-if="missionsToDisplay.length > 0">Mes missions</h2>
      <div class="flex flex-col gap-4">
        <mission-block
          v-for="(mission, i) in missionsToDisplay"
          :key="i"
          :mission="mission"
          @click="
            $router.push({
              name: 'mission',
              params: { courseId: mission.learningCourseId, moduleId: mission.learningModuleId }
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<style module>
.VLogbook {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
