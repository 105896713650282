<script setup lang="ts">
import { default as PCheckbox } from 'primevue/checkbox'
import { default as PButton } from 'primevue/button'
import { computed, type Ref, ref } from 'vue'
import type { IMCQAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer'])

const props = defineProps<{
  answersContent: IMCQAnswer[]
}>()

const answers: Ref<string[]> = ref([])

const getCorrectAnswers = computed(() => {
  return props.answersContent.filter((answer) => answer.isCorrect).map((answer) => answer.label)
})

const areSelectedAnswersCorrect = computed(() => {
  return (
    answers.value.length === getCorrectAnswers.value.length &&
    getCorrectAnswers.value.every((answer) => answers.value.includes(answer))
  )
})

const getAnswersSelected = computed(() => {
  return props.answersContent
    .filter((answer) => answers.value.includes(answer.label))
    .map((answer) => answer.label)
})

const handleAnswer = () => {
  let userAnswer = {} as IUserAnswer

  userAnswer.answers = getAnswersSelected.value
  userAnswer.isCorrect = areSelectedAnswersCorrect.value

  emit('answer', userAnswer)
}
</script>

<template>
  <form class="grid grid-cols-1 gap-4 w-full auto-rows-[minmax(min-content,1fr)]">
    <div v-for="(answer, i) in answersContent" class="flex items-center gap-2" :key="'answer_' + i">
      <p-checkbox :value="answer.label" :inputId="answer.label" v-model="answers" />
      <label
        class="text bg-surface-blue rounded-lg p-4 flex-grow h-full flex items-center min-h-24"
        :for="answer.label"
        >{{ answer.label }}</label
      >
    </div>
  </form>

  <div class="fixed bottom-0 w-full flex justify-center p-4 pt-8" :class="$style.QuizFooter">
    <p-button
      label="Valider"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :outlined="answers.length === 0"
      :disabled="answers.length === 0"
    />
  </div>
</template>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, var(--surface-blue-500) 0%, var(--surface-blue-500) 100%);
}
</style>
