<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import { default as PImage } from 'primevue/image'
import MascotteMissionUrl from '@/assets/images/mascottes/Mascotte_mission_01.png'
import MascotteMissionUrl_02 from '@/assets/images/mascottes/Mascotte_mission_02.svg'
import type { Mission } from '@/assets/ts/classes/progress/mission'
import { computed } from 'vue'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import type { IProgressMission } from '@/assets/ts/types/progress/Progress'

const props = defineProps<{
  mission: IProgressMission
}>()

const taskCount = computed(() => {
  return `${props.mission.countCompletedLearningGrain}/${props.mission.countTotalLearningGrain}`
})

const explorationName = computed(() => {
  const explorationName = 'Orion'
  if (props.mission.learningModuleOrder < 10) {
    return `${explorationName}-0${props.mission.learningModuleOrder}`
  } else if (props.mission.learningModuleOrder < 100) {
    return `${explorationName}-${props.mission.learningModuleOrder}`
  } else {
    return `${explorationName}-00`
  }
})
</script>

<template>
  <div class="bg-white p-4 rounded-lg relative flex items-center relative bg-white shadow-2xl">
    <div class="grow relative">
      <p-tag
        class="uppercase font-secondary font-medium px-2 py-0"
        :class="[
          mission.learningModuleOrder === 2
            ? 'bg-purple-300 text-purple-900'
            : 'bg-orange-300 text-orange-900 '
        ]"
        rounded
      >
        MISSION {{ explorationName }}
      </p-tag>
      <p class="text-primary font-bold mt-1 mb-8">{{ mission.title }}</p>

      <e-progress-bar
        :max="mission.countTotalLearningGrain"
        :value="mission.countCompletedLearningGrain"
      >
        <template #content>
          <p class="font-small text-primary mt-1">{{ taskCount }} tâches</p>
        </template>
      </e-progress-bar>
    </div>

    <p-image
      class="relative ml-auto"
      :src="mission.learningModuleOrder === 2 ? MascotteMissionUrl_02 : MascotteMissionUrl"
    />
  </div>
</template>
