<script setup lang="ts">
import { useSessionsStore } from '@/stores/sessions'
import { default as PButton } from 'primevue/button'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import MSupport from '@/components/modules/trainings/unit/MSupport.vue'
import pointsImgUrl from '@/assets/images/user-points.svg'
import MPageNavigator from '@/components/modules/pageNavigator/MPageNavigator.vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import ELoader from '@/components/elements/ELoader.vue'
import { default as PDialog } from 'primevue/dialog'
import { default as PRating } from 'primevue/rating'
import { default as PTextarea } from 'primevue/textarea'
import lottieAnimation from '@/assets/animations/json/mascotte_thumb_up_cool.json'
import { Vue3Lottie } from 'vue3-lottie'
import { useConfirm } from 'primevue/useconfirm'

import { default as PConfirmDialog } from 'primevue/confirmdialog'
import { useProfileStore } from '@/stores/profile'
import mascotImgURL from '@/assets/images/mascottes/MASCOTTE_Rassemblement-face_BG_NOIR.svg'
import { animateNabizKey } from '@/assets/ts/types/EventBusTypes'
import { useEventBus } from '@vueuse/core'

const router = useRouter()
const profileStore = useProfileStore()
const eventBus = useEventBus(animateNabizKey)

const {
  currentGrainSupportsWithEnd,
  currentCompletingTrainingSession,
  currentCompletingTrainingUnit,
  currentCompletingTrainingGrain,
  currentCompletingTrainingSupport,
  currentSupportIsLast,
  currentGrainIsLast
} = storeToRefs(useSessionsStore())

const {
  nextTrainingSupport,
  previousTrainingSupport,
  startTrainingSupport,
  completeTrainingSession,
  completeTrainingGrain,
  completeTrainingSupport,
  sendRating
} = useSessionsStore()

const { getPoints } = useProfileStore()

const showEnd = ref(false)
const showSupport = ref(false)
const showRating = ref(false)
const nextTimer = ref(null)
const timerLeftInSecs = ref(0)
const timerLeftInterval = ref(null)
const canNext = ref(currentCompletingTrainingGrain?.value.completed)
const pageNavigatorRef = ref(null)
const bodyWrapper: Ref<HTMLElement> = ref(null as unknown as HTMLElement)
const rating = ref([
  {
    label: 'Comment as-tu trouvé l’expérience ?',
    rate: 0,
    min: 1,
    max: 5
  },
  {
    label: 'As-tu compris le contenu et les exercices ?',
    rate: 0,
    min: 1,
    max: 5
  }
])
const review = ref('')
const ratingStep = ref(1)
const isMobileInLandscape = ref(false)
const PointsContainer = ref<HTMLElement | null>(null)
const RatingPointsContainer = ref<HTMLElement | null>(null)

window.addEventListener(
  'orientationchange',
  () => {
    isMobileInLandscape.value = window.orientation !== 0
  },
  true
)

onBeforeUnmount(() => {
  clearTimeout(nextTimer.value)
})

const disableBtnTerminate = computed(() => {
  return !currentCompletingTrainingGrain?.value.completed && !canNavigateToNext.value
})

const canNavigateToNext = computed(() => {
  return currentCompletingTrainingSupport.value.completed || canNext.value
})

async function previous() {
  previousTrainingSupport()
  showSupport.value = false
  canNext.value = currentCompletingTrainingSupport.value.completed
}

async function next() {
  if (showSupport.value) {
    await completeTrainingSupport(
      currentCompletingTrainingSupport.value.id,
      currentCompletingTrainingUnit.value.trainingUnitId
    )
  }
  nextTrainingSupport()
  showSupport.value = false
  canNext.value = currentCompletingTrainingSupport.value.started
}

async function startSupport() {
  showSupport.value = true
  await startTrainingSupport(
    currentCompletingTrainingSupport.value.id,
    currentCompletingTrainingUnit.value.trainingUnitId
  )
  // Disabled button 80% of estimatedTime or 0 if completed
  if (currentCompletingTrainingSupport.value.completed) canNext.value = true
  else {
    nextTimer.value = setTimeout(
      () => {
        canNext.value = true
        clearTimeout(nextTimer.value)
      },
      currentCompletingTrainingSupport.value.estimatedTime * 60 * 1000 * 0.8
    )
    timerLeftInSecs.value = currentCompletingTrainingSupport.value.estimatedTime * 60 * 0.8
    timerLeftInterval.value = setInterval(() => {
      timerLeftInSecs.value -= 1
      if (timerLeftInSecs.value <= 0) {
        timerLeftInSecs.value = 0
        clearInterval(timerLeftInterval.value)
      }
    }, 1000)
  }
}

async function leaveTraining() {
  await router.push({ name: 'home' })
}

/**
 * Action pour le bouton Terminer
 */
async function terminate() {
  await completeTrainingGrain(
    currentCompletingTrainingGrain?.value.trainingGrainId,
    currentCompletingTrainingUnit.value.trainingUnitId
  )
  if (currentGrainIsLast.value) {
    await completeTrainingSession(
      currentCompletingTrainingSession.value.trainingSessionId,
      currentCompletingTrainingUnit.value.trainingUnitId
    )
  }

  showEnd.value = true
  eventBus.emit({
    points: currentCompletingTrainingGrain?.value.points,
    container: PointsContainer.value
  })
}

async function validateRating() {
  ratingStep.value++

  if (rating.value[0].rate === 0 && rating.value[1].rate === 0 && review.value === '') {
    return
  }
  await sendRating(
    currentCompletingTrainingGrain?.value.trainingGrainId,
    currentCompletingTrainingUnit.value.trainingUnitId,
    rating.value,
    review.value
  )
  await getPoints()

  eventBus.emit({
    points: 10,
    container: RatingPointsContainer.value
  })
}

async function terminateRating() {
  showRating.value = false
  await router.push({ name: 'home' })
}

const displayUntilTimeMsg = computed(() => {
  if (!canNext.value && (!timerLeftInSecs.value || timerLeftInSecs.value === 0)) {
    return `Un dernier effort avant de terminer ! Clique sur "C'est parti !"`
  } else if (timerLeftInSecs.value > 0) {
    const minutes = Math.floor(timerLeftInSecs.value / 60)
    const secondes = timerLeftInSecs.value - minutes * 60
    return `Temps restant avant de terminer : ${minutes < 10 ? '0' : ''}${minutes}:${
      secondes < 10 ? '0' : ''
    }${secondes}`
  } else {
    return `Clique sur "Terminer" pour gagner tes points !`
  }
})

onMounted(() => {
  bodyWrapper.value = document.getElementById('body-wrapper') as HTMLElement
  isMobileInLandscape.value = window.orientation !== 0 && window.innerWidth < 1024
})

const confirm = useConfirm()
const confirmConfim = ref(false)

onBeforeRouteLeave((to, from, next) => {
  if (currentSupportIsLast && currentCompletingTrainingGrain?.value.completed) next()
  else if (!confirmConfim.value)
    confirm.require({
      message: 'Tu es sur le point de quitter ton parcours. Es-tu sûr(e) ?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        confirmConfim.value = true
        next()
      },
      reject: () => {
        next(false)
      }
    })
  else next()
})
</script>

<template>
  <p-confirm-dialog></p-confirm-dialog>
  <p-dialog
    :pt="{
      root: {
        style: 'width: 100%; max-width: 38rem; background-color: var(--primary-white);'
      }
    }"
    :visible="showRating"
    modal
    :closable="false"
    :append-to="bodyWrapper"
  >
    <div v-if="ratingStep === 1" class="RatingReview">
      <h6 class="tall">
        Bravo!<br />
        Tu as terminé ta planète
      </h6>

      <div v-for="(question, index) in rating" :key="`question_${index}`" class="RatingReview-Item">
        <p class="italic light tall">{{ question.label }}</p>
        <p-rating
          v-model="question.rate"
          :pt="{
            item: {
              style: 'width: 2rem; height: 2rem; fill: var(--primary-lavender);'
            }
          }"
          :cancel="false"
        >
          <template #onicon>
            <svg
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              data-pc-section="onicon"
            >
              <g clip-path="url(#pv_icon_clip_pv_id_132)">
                <path
                  d="M13.9718 5.36453C13.9398 5.26298 13.8798 5.17252 13.7986 5.10356C13.7175 5.0346 13.6186 4.98994 13.5132 4.97472L9.37043 4.37088L7.51307 0.617955C7.46021 0.529271 7.38522 0.455834 7.29545 0.404836C7.20568 0.353838 7.1042 0.327026 7.00096 0.327026C6.89771 0.327026 6.79624 0.353838 6.70647 0.404836C6.6167 0.455834 6.54171 0.529271 6.48885 0.617955L4.63149 4.37088L0.488746 4.97472C0.383363 4.98994 0.284416 5.0346 0.203286 5.10356C0.122157 5.17252 0.0621407 5.26298 0.03014 5.36453C-0.00402286 5.46571 -0.00924428 5.57442 0.0150645 5.67841C0.0393733 5.7824 0.0922457 5.87753 0.167722 5.95308L3.17924 8.87287L2.4684 13.0003C2.45038 13.1066 2.46229 13.2158 2.50278 13.3157C2.54328 13.4156 2.61077 13.5022 2.6977 13.5659C2.78477 13.628 2.88746 13.6644 2.99416 13.6712C3.10087 13.678 3.20733 13.6547 3.30153 13.6042L7.00096 11.6551L10.708 13.6042C10.79 13.6491 10.882 13.6728 10.9755 13.673C11.0958 13.6716 11.2129 13.6343 11.3119 13.5659C11.3988 13.5022 11.4663 13.4156 11.5068 13.3157C11.5473 13.2158 11.5592 13.1066 11.5412 13.0003L10.8227 8.87287L13.8266 5.95308C13.9033 5.87835 13.9577 5.7836 13.9833 5.67957C14.009 5.57554 14.005 5.4664 13.9718 5.36453Z"
                ></path>
              </g>
            </svg>
          </template>
          <template #officon>
            <svg
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
              class="p-icon p-rating-icon"
              aria-hidden="true"
              data-pc-section="officon"
            >
              <g clip-path="url(#pv_icon_clip_pv_id_2)">
                <path
                  d="M10.9741 13.6721C10.8806 13.6719 10.7886 13.6483 10.7066 13.6033L7.00002 11.6545L3.29345 13.6033C3.19926 13.6539 3.09281 13.6771 2.98612 13.6703C2.87943 13.6636 2.77676 13.6271 2.6897 13.5651C2.60277 13.5014 2.53529 13.4147 2.4948 13.3148C2.45431 13.215 2.44241 13.1058 2.46042 12.9995L3.17881 8.87264L0.167699 5.95324C0.0922333 5.8777 0.039368 5.78258 0.0150625 5.67861C-0.00924303 5.57463 -0.00402231 5.46594 0.030136 5.36477C0.0621323 5.26323 0.122141 5.17278 0.203259 5.10383C0.284377 5.03488 0.383311 4.99023 0.488681 4.97501L4.63087 4.37126L6.48797 0.618832C6.54083 0.530159 6.61581 0.456732 6.70556 0.405741C6.79532 0.35475 6.89678 0.327942 7.00002 0.327942C7.10325 0.327942 7.20471 0.35475 7.29447 0.405741C7.38422 0.456732 7.4592 0.530159 7.51206 0.618832L9.36916 4.37126L13.5114 4.97501C13.6167 4.99023 13.7157 5.03488 13.7968 5.10383C13.8779 5.17278 13.9379 5.26323 13.9699 5.36477C14.0041 5.46594 14.0093 5.57463 13.985 5.67861C13.9607 5.78258 13.9078 5.8777 13.8323 5.95324L10.8212 8.87264L11.532 12.9995C11.55 13.1058 11.5381 13.215 11.4976 13.3148C11.4571 13.4147 11.3896 13.5014 11.3027 13.5651C11.2059 13.632 11.0917 13.6692 10.9741 13.6721ZM7.00002 10.4393C7.09251 10.4404 7.18371 10.4613 7.2675 10.5005L10.2098 12.029L9.65193 8.75036C9.6368 8.6584 9.64343 8.56418 9.6713 8.47526C9.69918 8.38633 9.74751 8.30518 9.81242 8.23832L12.1969 5.94559L8.90298 5.45648C8.81188 5.44198 8.72555 5.406 8.65113 5.35152C8.57671 5.29703 8.51633 5.2256 8.475 5.14314L7.00002 2.1626L5.52503 5.15078C5.4837 5.23324 5.42332 5.30467 5.3489 5.35916C5.27448 5.41365 5.18815 5.44963 5.09705 5.46412L1.80318 5.94559L4.18761 8.23832C4.25252 8.30518 4.30085 8.38633 4.32873 8.47526C4.3566 8.56418 4.36323 8.6584 4.3481 8.75036L3.7902 12.0519L6.73253 10.5234C6.81451 10.4762 6.9058 10.4475 7.00002 10.4393Z"
                ></path>
              </g>
            </svg>
          </template>
        </p-rating>
      </div>

      <div class="RatingReview-Item">
        <p class="italic light tall">Souhaites-tu partager un retour ?</p>
        <p-textarea
          style="width: 100%; max-width: 100%"
          v-model="review"
          :rows="5"
          placeholder="Partage tes remarques ici"
        />
      </div>

      <div class="RatingReview-Button-Container">
        <p-button
          label="Passer"
          outlined
          severity="primary"
          rounded
          @click="$router.push({ name: 'home' })"
        />
        <p-button
          ref="RatingValidateButton"
          label="Valider"
          rounded
          severity="primary"
          @click="validateRating()"
        />
      </div>
    </div>
    <div class="RatingReview-End" v-else>
      <vue3-lottie
        :animation-data="lottieAnimation"
        assets-path="/animationsImages/thumb_up_cool/"
      />
      <div class="RatingReview-End-Text">
        <div ref="RatingPointsContainer" class="RatingReview-End-Points">
          <span>+10</span>
          <img :src="pointsImgUrl" alt="Icône représentant une planète et 2 orbites l'entourant" />
        </div>

        <h5 class="font-semibold text-primary">Merci pour tes retours</h5>

        <h5 class="font-semibold text-primary">Bravo et à la prochaine</h5>

        <p-button label="Terminer" @click="terminateRating()" rounded severity="primary" />
      </div>
    </div>
  </p-dialog>

  <div class="Grain" :class="[isMobileInLandscape ? 'Grain--landscape' : '']">
    <div v-if="isMobileInLandscape" class="Grain-Sidebar">
      <div>
        <h4>Planète {{ currentCompletingTrainingGrain?.order }}</h4>
        <h5 class="Session-Title">{{ currentCompletingTrainingGrain?.title }}</h5>
      </div>

      <div class="Grain-Points">
        <p class="light small">Durée: {{ currentCompletingTrainingGrain?.estimatedTime }}min</p>
        <div>
          <img :src="pointsImgUrl" alt="Icône représentant une planète et 2 orbites l'entourant" />
          <span>{{ currentCompletingTrainingGrain?.points }}</span>
        </div>
      </div>

      <p-button class="exit" label="Quitter" outlined rounded @click="leaveTraining" />
      <p-button
        @click="terminate"
        :disabled="disableBtnTerminate"
        v-if="currentSupportIsLast && !currentCompletingTrainingGrain?.completed"
        class="next"
        label="Terminer"
        rounded
      />
    </div>
    <template v-if="currentCompletingTrainingGrain?.trainingSupports.length">
      <div class="Grain-Body">
        <template v-if="!showEnd">
          <m-support
            v-for="support in currentGrainSupportsWithEnd"
            :key="`unit_${support.id}`"
            :grain="currentCompletingTrainingGrain"
            :currentSupport="currentCompletingTrainingSupport.order"
            :support="support"
            @start-support="startSupport()"
            @previous="previous()"
            @next="next()"
            @validate="terminate"
          />
        </template>

        <div v-if="showEnd" class="Grain-End">
          <h1>Bravo {{ profileStore.me.firstname }} !</h1>
          <p class="tall">
            Tu as terminé la planète {{ currentCompletingTrainingGrain?.order }} : <br />
            <span class="font-bold tall">{{ currentCompletingTrainingGrain?.title }}</span>
          </p>

          <div>
            <p class="tall">Tu as gagné</p>
            <div ref="PointsContainer" class="Grain-Points">
              <span>{{ currentCompletingTrainingGrain?.points }}</span>
              <img :src="pointsImgUrl" alt="Icone d'une planète avec des orbites" />
            </div>
          </div>

          <p-button
            label="Donner mon avis"
            rounded
            severity="light"
            @click="() => (showRating = true)"
          />
        </div>
        <img v-if="showEnd" :src="mascotImgURL" class="Grain-Mascot" alt="Mascotte Naboo" />
      </div>

      <div class="Grain-Footer" :class="[isMobileInLandscape ? 'Grain-Footer--landscape' : '']">
        <div v-if="!isMobileInLandscape">
          <h4>Planète {{ currentCompletingTrainingGrain?.order }}</h4>
          <h5 class="Session-Title">{{ currentCompletingTrainingGrain?.title }}</h5>
        </div>

        <div class="Grain-Points" v-if="!isMobileInLandscape">
          <p class="light">Durée: {{ currentCompletingTrainingGrain?.estimatedTime }}min</p>
          <div>
            <img
              :src="pointsImgUrl"
              alt="Icône représentant une planète et 2 orbites l'entourant"
            />
            <span>{{ currentCompletingTrainingGrain?.points }}</span>
          </div>
        </div>

        <div class="Grain-Nav">
          <m-page-navigator
            ref="pageNavigatorRef"
            :completed="
              currentGrainSupportsWithEnd
                .map((support) => (support.completed ? support.order : null))
                .filter((support) => !!support)
            "
            :total-pages="currentGrainSupportsWithEnd.length"
            :current-page="currentCompletingTrainingSupport.order"
            :canNext="canNavigateToNext"
            :untilTimeInSecs="timerLeftInSecs"
            @next="next()"
            @previous="previous()"
          />
        </div>

        <p-button
          v-if="!isMobileInLandscape"
          class="exit"
          label="Quitter"
          outlined
          rounded
          @click="leaveTraining"
        />
        <span
          v-if="
            currentSupportIsLast &&
            !currentCompletingTrainingGrain?.completed &&
            !isMobileInLandscape
          "
          v-tooltip.top="{
            value: `${displayUntilTimeMsg}`,
            fitContent: false,
            class: 'custom-tooltip'
          }"
        >
          <p-button
            @click="terminate"
            :disabled="disableBtnTerminate"
            v-if="currentSupportIsLast && !currentCompletingTrainingGrain?.completed"
            class="next"
            label="Terminer"
            rounded
          />
        </span>
      </div>
    </template>
    <template v-else>
      <e-loader circle :overlayed="false" size="medium" :with-animation="false" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.RatingReview {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;
  color: var(--primary-night-blue);

  .RatingReview-Item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .RatingReview-Button-Container {
    display: flex;
    gap: 1rem;
  }
}

.RatingReview-End {
  display: flex;
  justify-content: center;
  align-items: center;

  &-Text {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    color: var(--primary-dark-blue);
  }

  &-Points {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span {
      font-size: 2rem;
    }
  }
}

.Grain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: var(--primary-dark-blue);

  .Grain-Body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: url('@/assets/images/backgroundAssets/sessions/Dark_2.svg');
    background-size: cover;

    .Grain-Mascot {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 50%;
      max-height: 50%;
      pointer-events: none;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .Grain-End {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      color: var(--variant-white);
      width: 100%;

      .Grain-Points {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
          font-size: 5rem;
          font-weight: 700;

          @media screen and (max-width: 768px) {
            font-size: 2rem;
          }
        }

        img {
          width: 6.32369rem;
          height: 4.125rem;

          @media screen and (max-width: 768px) {
            width: 3.16184rem;
            height: 2.0625rem;
          }
        }
      }

      @media screen and (max-width: 768px) {
        gap: 1rem;
      }
    }
  }

  &--landscape {
    display: grid;
    grid-template:
      [main-left] 'sidebar  main' 1fr [main-right]
      [footer-left] 'sidebar  foot' 3rem [footer-right]
      / 2fr 5fr;

    .Grain-Sidebar {
      grid-area: sidebar;
      width: 100%;
    }

    .Grain-Body {
      grid-area: main;
    }

    .Grain-Footer {
      grid-area: foot;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.Grain-Footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--primary-dark-blue);
  padding: 1rem 2rem;
  color: var(--variant-white);
  gap: 2rem;

  > div:nth-child(1) {
    max-width: 20%;
  }

  .exit {
    border-color: var(--variant-white);
    color: var(--variant-white);
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
  }

  .Session-Title {
    line-height: 1.375rem;
  }

  h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Grain-Points {
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    img {
      width: 2.48344rem;
      height: 1.61994rem;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  .Grain-Nav {
    margin-left: auto;
  }

  &--landscape {
    justify-content: center !important;
    width: 75%;
    padding: 0 !important;

    .Grain-Nav {
      width: auto !important;
      margin: unset !important;
    }

    > div:nth-child(1) {
      max-width: unset;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 1rem;
    gap: 0.5rem;
    justify-content: space-between;

    > div:nth-child(1) {
      max-width: 100%;
      width: 100%;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      h4 {
        display: none;
      }
    }

    .Grain-Points {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .Grain-Nav {
      margin-left: unset;
    }
  }
}

.Grain-Sidebar {
  padding: 1rem;
  gap: 1rem;
  color: var(--variant-white);
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: start;

  h4 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .Grain-Points {
    margin-left: 2rem;
    margin-bottom: auto;

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    img {
      width: 2.48344rem;
      height: 1.61994rem;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
}
</style>
