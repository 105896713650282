<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'
import { default as PImage } from 'primevue/image'
import MascotteMissionUrl from '@/assets/images/mascottes/Mascotte_mission_01.svg'
import MascotteMissionUrl_02 from '@/assets/images/mascottes/Mascotte_mission_02.svg'
import type { Mission } from '@/assets/ts/classes/training/mission'
import { computed } from 'vue'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import EIcon from '@/components/elements/icons/EIcon.vue'
import type { IProgressQuiz } from '@/assets/ts/types/progress/Progress'

const props = defineProps<{
  quiz: IProgressQuiz
}>()

const explorationName = computed(() => {
  return 'exploration ' + props.quiz.learningCourseName
})
</script>

<template>
  <e-icons :load="['Play']" />
  <div
    class="bg-primary-night-blue p-4 rounded-lg relative flex items-center gap-4 shadow-2xl"
    :class="[$style.MissionBlock]"
    @click="
      () =>
        $router.push({
          name: 'diagnostic',
          query: {
            exploration: props.quiz.learningCourseSlug
          }
        })
    "
  >
    <div class="grow relative">
      <p-tag
        class="uppercase font-secondary font-medium px-2 py-0"
        :class="[
          quiz.order === 2 ? 'bg-purple-300 text-purple-900' : 'bg-orange-300 text-orange-900 '
        ]"
        rounded
      >
        {{ explorationName }}
      </p-tag>
      <p class="text-white font-bold mt-1">Nouveau test d'entrée disponible</p>
    </div>

    <p-button text icon="pi pi-play" class="ml-auto w-16 h-16 px-4 py-4" severity="primary" rounded>
      <e-icon name="Play" type="svg" style="--fill: var(--variant-white)" />
    </p-button>
  </div>
</template>

<style module>
.MissionBlock {
  position: relative;
  background: var(--linear-04);

  .MissionBlockTasks {
    --taskWidth: 0;
    position: relative;
    background: var(--primary-night-blue);
    border-radius: 0.5rem;
    height: 10px;
    width: 100%;
    background-color: var(--secondary);

    &::before {
      content: '';
      display: block;
      position: relative;
      width: var(--taskWidth);
      height: 100%;
      background-color: var(--primary-lavender);
      border-radius: 0.5rem;
    }
  }
}

.MissionBlock::before {
  content: '';
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--primary-night-blue);
  border-radius: var(--p-content-border-radius);
}
</style>
