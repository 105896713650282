import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import type { IXAPIStatementType } from '@/assets/ts/types/xAPI/xAPI'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/ts/classes/Error'

export const usexAPIStore = defineStore('xAPI', () => {
  // STATE
  const xAPIQueue: Ref<IXAPIStatementType[]> = ref([])

  // MUTATIONS
  async function addToQueue(statement: IXAPIStatementType) {
    xAPIQueue.value.push(statement)
    await processQueue()
  }

  function removeFromQueue() {
    xAPIQueue.value.shift()
  }

  // Process the queue while there are statements in it
  async function processQueue() {
    if (xAPIQueue.value.length === 0) return

    // Process the statement
    try {
      await nabooApi.sendxAPIStatement(xAPIQueue.value[0])
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de l'envoi des données.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      if (nabooError.getCode() === 'ERR_NETWORK') {
        setTimeout(() => {
          processQueue()
        }, 5000)
      }
    }

    // Remove the statement from the queue
    removeFromQueue()

    // Process the next statement
    await processQueue()
  }

  return {
    xAPIQueue,
    addToQueue,
    removeFromQueue
  }
})
