<script setup lang="ts">
import { default as PAvatar } from 'primevue/avatar'
import Avatar_url_1 from '@/assets/images/mascottes/grades/AVATAR_Grade_01.svg'
import Avatar_url_2 from '@/assets/images/mascottes/grades/AVATAR_Grade_02.svg'
import Avatar_url_3 from '@/assets/images/mascottes/grades/AVATAR_Grade_03.svg'
import Avatar_url_4 from '@/assets/images/mascottes/grades/AVATAR_Grade_04.svg'
import Avatar_url_5 from '@/assets/images/mascottes/grades/AVATAR_Grade_05.svg'
import { computed, onMounted, useCssModule } from 'vue'

const props = defineProps<{
  points: number
  size: 'small' | 'large'
  color?: 'yellow' | 'blue'
  avatar?: string
}>()

const style = useCssModule()

const avatarUrl = computed(() => {
  switch (props.points) {
    case 1:
      return Avatar_url_1
    case 1.5:
      return Avatar_url_1
    case 2:
      return Avatar_url_2
    case 2.5:
      return Avatar_url_2
    case 3:
      return Avatar_url_3
    case 3.5:
      return Avatar_url_3
    case 4:
      return Avatar_url_4
    case 4.5:
      return Avatar_url_4
    case 5:
      return Avatar_url_5
    default:
      return Avatar_url_1
  }
})

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'small':
      return { avatar: 'small', container: 'w-16 h-16', star: 'w-3' }
    case 'large':
      return { avatar: 'xlarge', container: 'w-32 h-32', star: 'w-6' }
    default:
      return { avatar: 'small', container: 'w-16 h-16', star: 'w-3' }
  }
})

onMounted(() => {
  setTimeout(() => {
    const stars = document.querySelectorAll(`.${style.test}`)

    stars.forEach((star, index) => {
      const htmlStar = star as HTMLElement

      setTimeout(() => {
        htmlStar.style.opacity = '1'
        htmlStar.style.width = '100%'
      }, index * 100)
    })
  }, 100)
})
</script>

<template>
  <div
    class="relative p-2 pb-0 mb-1 flex flex-col justify-between items-center"
    :class="[$style.MStarGrades, sizeClasses.container]"
  >
    <svg class="w-full absolute top-0" viewBox="0 0 60 68" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H57C58.6569 0 60 1.34315 60 3V47.3055C60 48.3324 59.4748 49.2879 58.6079 49.8382L31.6079 66.9792C30.6265 67.6023 29.3735 67.6023 28.3921 66.9792L1.3921 49.8382C0.525196 49.2879 0 48.3324 0 47.3055V3Z"
        style="fill: var(--fill)"
      />
      <defs>
        <linearGradient
          id="linear_01"
          x1="46.6452"
          y1="82.2581"
          x2="25.7376"
          y2="-11.0662"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2" stop-color="#FF7C4F" />
          <stop offset="1" stop-color="#FBDE68" />
        </linearGradient>
      </defs>
    </svg>

    <p-avatar
      :size="sizeClasses.avatar"
      :image="avatarUrl"
      shape="circle"
      class="relative mb-auto"
    />

    <div class="flex relative justify-center" :class="$style['MStarGrades-Stars']">
      <div
        class="flex items-center relative"
        v-for="i in 5"
        :key="i"
        :class="$style['MStarGrades-Star']"
      >
        <svg
          class="relative"
          :class="sizeClasses.star"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M6.41517 1.31559L7.32436 3.05591V3.05544C7.47291 3.34026 7.76057 3.53733 8.09295 3.58285L10.1264 3.86203C10.5105 3.91459 10.8299 4.16891 10.9502 4.518C11.0699 4.86663 10.97 5.25 10.6918 5.50573L9.22026 6.86034H9.22076C8.9798 7.08182 8.8705 7.40089 8.92714 7.71339L9.27442 9.62597V9.62644C9.34 9.98774 9.18251 10.3533 8.86852 10.5691C8.55402 10.7845 8.13768 10.8131 7.79388 10.6423L5.97497 9.73906C5.67738 9.59172 5.32264 9.59172 5.02503 9.73906L3.20613 10.6423C2.86233 10.8131 2.44598 10.7845 2.13149 10.5691C1.81749 10.3533 1.66001 9.98775 1.72559 9.62644L2.07287 7.71386V7.71339C2.13001 7.40089 2.02021 7.08182 1.77974 6.86034L0.308183 5.50573C0.0299605 5.25 -0.0699072 4.86665 0.0498399 4.518C0.170072 4.16891 0.489527 3.91459 0.873583 3.86203L2.90706 3.58285C3.23944 3.53733 3.5271 3.34026 3.67565 3.05544L4.58484 1.31512V1.31559C4.75675 0.986191 5.11147 0.77832 5.49999 0.77832C5.88851 0.77832 6.24327 0.986191 6.41517 1.31559Z"
              fill="#EEC137"
            />
          </g>
        </svg>

        <svg
          v-if="points >= i"
          :class="$style['test']"
          class="absolute"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7658 1.40044L18.0002 5.93673V5.93551C18.3652 6.67792 19.0722 7.1916 19.889 7.31024L24.8864 8.03796C25.8303 8.17495 26.6153 8.83786 26.9109 9.74781C27.2051 10.6565 26.9597 11.6558 26.276 12.3224L22.6595 15.8533H22.6607C22.0685 16.4306 21.7999 17.2623 21.9391 18.0769L22.7926 23.0622V23.0634C22.9538 24.0052 22.5667 24.9579 21.795 25.5206C21.0221 26.082 19.999 26.1565 19.154 25.7114L14.6839 23.357C13.9526 22.9729 13.0808 22.9729 12.3494 23.357L7.87929 25.7114C7.03438 26.1566 6.01117 26.082 5.2383 25.5206C4.46662 24.9579 4.0796 24.0052 4.24076 23.0634L5.09424 18.0781V18.0769C5.23465 17.2623 4.96481 16.4306 4.37385 15.8533L0.757383 12.3224C0.0736301 11.6558 -0.171802 10.6566 0.122485 9.74781C0.417964 8.83786 1.20305 8.17495 2.14689 8.03796L7.14431 7.31024C7.96116 7.1916 8.6681 6.67792 9.03319 5.93551L11.2676 1.39921V1.40044C11.6901 0.541832 12.5618 0 13.5166 0C14.4715 0 15.3433 0.541833 15.7658 1.40044Z"
            fill="#FFD80E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.2737 25.0316C22.1314 25.2147 21.9625 25.3798 21.7696 25.5204C20.9967 26.0818 19.9735 26.1564 19.1286 25.7112L14.6585 23.3568C13.9272 22.9727 13.0554 22.9727 12.324 23.3568L7.85388 25.7112C7.00896 26.1564 5.98575 26.0818 5.21288 25.5204C5.13393 25.4628 5.05901 25.4012 4.98828 25.3359L13.7578 14.5625L22.2737 25.0316Z"
            fill="#C8AA0E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7016 0.00635744C13.6423 0.00213774 13.5827 0 13.5227 0C12.5679 0 11.6961 0.541832 11.2737 1.40044V1.39921L9.03928 5.93551C8.67419 6.67792 7.96724 7.1916 7.1504 7.31024L2.15298 8.03796C1.36839 8.15184 0.693495 8.62914 0.318359 9.30966L13.7639 14.5626L13.7016 0.00635744Z"
            fill="#FFF2B1"
          />
        </svg>

        <svg
          :class="$style['test']"
          v-if="points === i - 0.5"
          class="absolute"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.735 6.10823L13.5 2L13.515 0C13.515 0 11.6886 0.541766 11.2662 1.40026V1.39904L9.03208 5.93478C8.66704 6.6771 7.96018 7.19072 7.14343 7.30934L2.14663 8.03698C1.2029 8.17394 0.417912 8.83677 0.12247 9.74661C-0.171781 10.6553 0.073621 11.6544 0.75729 12.3209L4.37331 15.8514C4.9642 16.4286 5.23401 17.2602 5.09361 18.0747V18.0759L4.24024 23.0606C4.07909 24.0022 4.46607 24.9549 5.23766 25.5174C6.01043 26.0788 7.03351 26.1534 7.87832 25.7082L12.3479 23.3541C12.7805 23.1269 13.2623 23.0341 13.735 23.0757V6.10823Z"
            fill="#FFD80E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7553 23.0801C13.2673 23.0277 12.7676 23.119 12.3204 23.3538L7.85083 25.7079C7.00602 26.1531 5.98294 26.0785 5.21017 25.5171C5.13147 25.4598 5.05677 25.3983 4.98625 25.3333L13.7553 14.5606V23.0801Z"
            fill="#C8AA0E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.699 0.00635967C13.6397 0.00213849 13.5801 0 13.5201 0C12.5654 0 11.6937 0.541766 11.2713 1.40026V1.39904L9.0372 5.93478C8.67216 6.6771 7.9653 7.19072 7.14855 7.30934L2.15175 8.03698C1.36727 8.15083 0.692477 8.62805 0.317383 9.30847L13.7613 14.5608L13.699 0.00635967Z"
            fill="#FFF2B1"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style module>
.MStarGrades {
  --fill: url(#linear_01);

  &.MStarGrades--linear-02 {
    --fill: url(#linear_02);
  }

  &.MStarGrades--linear-03 {
    --fill: url(#linear_03);
  }

  .MStarGrades-Stars {
    .MStarGrades-Star {
      svg:first-child {
        mix-blend-mode: multiply;
      }

      &:last-child,
      &:first-child {
        svg {
          transform: translateY(-135%);
        }
      }

      &:first-child {
        margin-right: -4%;
      }

      &:last-child {
        margin-left: -4%;
      }

      &:nth-child(2),
      &:nth-child(4) {
        svg {
          transform: translateY(-50%);
        }
      }
    }
  }
}

.test {
  transition: all 1s ease-in-out;
  width: 130%;
  opacity: 0;
}
</style>
