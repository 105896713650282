<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useCssModule, watch } from 'vue'
import { useMagicKeys } from '@vueuse/core'
import { default as PDialog } from 'primevue/dialog'
import { default as PButton } from 'primevue/button'
import TMain from '@/components/templates/TMain.vue'
import { storeToRefs } from 'pinia'
import { default as PImage } from 'primevue/image'
import logoUrl from '@/assets/images/logo-naboo-blanc.svg'
import MacotteTimideUrl from '@/assets/images/mascottes/MASCOTTE_Timide_Oh.svg'

const { initLogin, loginWithRedirect, checkSession } = useAuthStore()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { putTrackingConsentBanner, initSessionUUID } = useProfileStore()
const { hasAnsweredTrackingConsentBanner, hasConsentedForTracking } = storeToRefs(useProfileStore())

// Get the router
const router = useRouter()

// Cheats to get current version of the app
const keys = useMagicKeys()
const versionKeys = keys['Ctrl+Alt+v']
watch(versionKeys, (pressed) => {
  if (pressed) alert(`Version : ${__APP_NAME__}@${__APP_FULL_VERSION__}`)
})

// Consent Cookies
const showConsent = ref(false)
const consent = ref(false)

const loading = ref(true)

onMounted(async () => {
  await router.isReady()
  initSessionUUID()
  await checkSession()
  if (isAuthenticated.value) {
    await initLogin()
    await nextTick(() => {
      showConsent.value = !hasAnsweredTrackingConsentBanner.value
      consent.value = hasConsentedForTracking.value
      loading.value = false
    })
  } else if (!router.currentRoute.value.query.code && !router.currentRoute.value.query.state) {
    await loginWithRedirect()
    loading.value = false
  }
})

onBeforeUnmount(() => {
  showConsent.value = false
})

async function toConsent() {
  await putTrackingConsentBanner()
  showConsent.value = false
  consent.value = true
}
</script>

<template>
  <t-main :with-header="isAuthenticated ? !$route.meta.hideHeader : false">
    <router-view v-if="!loading" />
    <p-dialog
      id="dialog-consent"
      :visible="showConsent"
      :closable="false"
      modal
      :show-header="false"
      :show-footer="false"
      :draggable="false"
      content-style="display: flex; flex-direction: column; max-width:30rem; background-color: #fff; border-radius: 10px; padding: 1.25rem; gap: 1.25rem;"
    >
      <h6>Utilisation des données</h6>
      <p class="italic">
        En utilisant cette application, vous acceptez que vos données soient utilisées à des fins
        pédagogiques.
      </p>
      <p-button label="J'accepte" rounded severity="primary" @click="toConsent" />
    </p-dialog>
  </t-main>
</template>
