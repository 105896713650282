import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

router.afterEach((to) => {
  document.title = `Naboo | ${to.meta.title || 'Accueil'}`
})

export default router
