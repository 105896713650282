import type { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router'
import { storeToRefs } from 'pinia'
import slug from 'slug'
import { useSessionsStore } from '@/stores/sessions'
import { useEMethodosStore } from '@/stores/emethodos'

export const clearQueryParamsVersion = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.query.v) {
    const query = { ...to.query }
    delete query.v
    next({ name: to.name as string, query: query })
    return
  }
  next()
}

export const updateUrlPath = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { currentCompletingTrainingSession, currentCompletingTrainingGrain } =
    storeToRefs(useSessionsStore())

  const { getVideo } = storeToRefs(useEMethodosStore())

  if (to.name === 'home' && currentCompletingTrainingSession.value && !to?.params?.sessionTitle) {
    next(`/${slug(currentCompletingTrainingSession.value?.title || '')}`)
    return
  }

  if (to.name === 'grain' && currentCompletingTrainingGrain.value && !to.params?.grainTitle) {
    next({
      name: 'grain',
      params: {
        sessionTitle: slug(currentCompletingTrainingSession.value?.title || ''),
        grainTitle: slug(currentCompletingTrainingGrain.value?.title || '')
      }
    })
    return
  }

  if (to.name === 'video' && getVideo.value?.title && !to.params?.videoTitle) {
    next({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
    return
  }

  next()
}

/**
 * Used to update the current url path when the user is completing a training session or grain
 */
export const updateCurrentUrlPath = async (router: Router, route: RouteLocationNormalized) => {
  const { currentCompletingTrainingSession, currentCompletingTrainingGrain } =
    storeToRefs(useSessionsStore())

  const { getVideo } = storeToRefs(useEMethodosStore())

  if (route.name === 'home' && currentCompletingTrainingSession.value) {
    await router.replace({ path: `/${slug(currentCompletingTrainingSession.value?.title || '')}` })
  }

  if (route.name === 'grain' && currentCompletingTrainingGrain.value) {
    await router.replace({
      name: 'grain',
      params: {
        sessionTitle: slug(currentCompletingTrainingSession.value?.title || ''),
        grainTitle: slug(currentCompletingTrainingGrain.value?.title || '')
      }
    })
  }

  if (route.name === 'video' && getVideo.value?.title && !route.params.videoTitle) {
    await router.replace({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
  }
}

export const isFeatureEnabled = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { isEnabled } = storeToRefs(useEMethodosStore())

  if (!isEnabled.value) {
    next({ name: 'home' })
    return
  }
  next()
}
