<script lang="ts" setup>
import { default as PTag } from 'primevue/tag'
import { computed, useCssModule } from 'vue'
import MStarScore from '@/components/modules/grades/MStarScore.vue'

const props = defineProps<{
  isActive: boolean
  task: { title: string; score: number }
  disabled?: boolean
}>()

const styles = useCssModule()

const classes = computed(() => {
  const containerClasses = []
  const titleClasses = []
  const chronoClasses = []

  if (props.isActive) {
    containerClasses.push(['bg-white', styles.active, 'mb-2'])
    titleClasses.push(['text-primary'])
    chronoClasses.push(['text-primary-500 border-primary-500'])
  } else if (props.disabled) {
    containerClasses.push(['bg-blue-800'])
    titleClasses.push([styles.TitleDisabled])
    chronoClasses.push([styles.ChronoDisabled])
  } else {
    containerClasses.push(['bg-primary-night-blue'])
    titleClasses.push(['text-white'])
    chronoClasses.push(['text-primary-500 border-primary-500'])
  }

  return {
    container: containerClasses,
    title: titleClasses,
    chrono: chronoClasses
  }
})
</script>

<template>
  <div
    class="w-full rounded relative flex items-center p-3"
    :class="[$style.MissionTask, classes.container]"
  >
    <div class="flex-1 flex flex-col gap-2 items-start">
      <p-tag
        class="font-secondary font-small font-medium bg-orange-100 text-orange-800 px-2 py-0"
        rounded
        >Tâche 001</p-tag
      >
      <h4 class="text font-bold" :class="classes.title">
        {{ task.title }}
      </h4>

      <m-star-score />
    </div>

    <div>
      <div class="flex rounded border p-1 items-center gap-1" :class="classes.chrono">
        <i class="pi pi-clock" />
        7min
      </div>
    </div>

    <div
      v-if="disabled"
      class="bg-primary-dark-blue-500 absolute w-full h-full rounded flex justify-center items-center left-0 top-0"
    >
      <i class="pi pi-lock w-20 h-20 text-6xl flex justify-center items-center"></i>
    </div>
  </div>
</template>

<style module>
.MissionTask {
  &.active {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      border: solid 1px var(--variant-white);
      border-radius: 0.5rem;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  .TitleDisabled {
    color: rgba(254, 254, 254, 0.6);
  }

  .ChronoDisabled {
    color: #9896f6;
    border-color: #9896f6;
  }

  .Lock {
  }
}
</style>
