<template>
  <div :class="$style.BOrbits">
    <svg viewBox="0 0 836 942" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M532.002 553.504C523.984 554.107 523.321 554.596 522.502 560.504C521.683 554.596 521.019 554.107 513.002 553.504C521.02 552.9 521.683 552.411 522.502 546.504C523.321 552.412 523.985 552.901 532.002 553.504Z"
        fill="#DDDCFF"
      />
      <path
        d="M293.653 362.197C351.037 295.243 416.897 246.838 476.97 222.689C537.069 198.529 591.232 198.692 625.397 228.603C659.562 258.514 667.447 312.673 652.359 376.24C637.277 439.778 599.267 512.565 541.883 579.519C484.499 646.472 418.639 694.878 358.566 719.027C298.467 743.187 244.304 743.023 210.139 713.112C175.974 683.201 168.089 629.042 183.177 565.476C198.259 501.937 236.269 429.15 293.653 362.197Z"
        stroke="#FEFCFB"
        stroke-opacity="0.25"
      />
      <path
        d="M316.525 382.222C363.358 327.579 417.106 288.077 466.125 268.372C515.17 248.655 559.339 248.803 587.187 273.184C615.036 297.565 621.479 341.728 609.166 393.602C596.859 445.449 565.841 504.85 519.008 559.493C472.175 614.136 418.428 653.637 369.408 673.343C320.363 693.059 276.195 692.912 248.346 668.531C220.498 644.15 214.054 599.987 226.368 548.113C238.674 496.265 269.693 436.865 316.525 382.222Z"
        stroke="#FEFCFB"
        stroke-opacity="0.25"
      />
      <path
        d="M339.895 402.681C375.948 360.616 417.321 330.211 455.047 315.045C492.798 299.869 526.755 299.999 548.15 318.73C569.545 337.462 574.516 371.412 565.038 411.341C555.566 451.244 531.692 496.967 495.639 539.033C459.586 581.098 418.213 611.503 380.487 626.668C342.736 641.845 308.779 641.715 287.384 622.983C265.989 604.252 261.018 570.302 270.496 530.372C279.968 490.47 303.842 444.746 339.895 402.681Z"
        stroke="#FEFCFB"
        stroke-opacity="0.25"
      />
      <path
        d="M274.627 345.539C340.787 268.347 416.722 212.536 485.988 184.69C555.281 156.835 617.758 157.012 657.177 191.522C696.595 226.033 705.679 288.506 688.283 361.797C670.893 435.06 627.067 518.981 560.907 596.174C494.748 673.367 418.813 729.178 349.546 757.023C280.253 784.879 217.777 784.702 178.358 750.191C138.94 715.681 129.855 653.207 147.252 579.917C164.642 506.654 208.468 422.732 274.627 345.539Z"
        stroke="#FEFCFB"
        stroke-opacity="0.25"
      />
    </svg>
  </div>
</template>

<style module>
.BOrbits {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  svg {
    height: 130%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65%;
    left: 60%;
  }
}

.BOrbits {
  animation: zoom-in-out 40s linear infinite;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
</style>
