<script lang="ts" setup>
import { default as PButton } from 'primevue/button'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    currentPage: number
    totalPages: number
    canNext: boolean
    untilTimeInSecs: number
    completed: number[]
  }>(),
  {
    currentPage: 1,
    totalPages: 1,
    canNext: false,
    untilTimeInSecs: 0
  }
)
const emits = defineEmits(['previous', 'next'])

function handlePrevious() {
  if (props.currentPage > 1) {
    emits('previous')
  }
}

function handleNext() {
  if (props.currentPage < props.totalPages && props.canNext) {
    emits('next')
  }
}

function showPrevious() {
  return props.currentPage > 1
}

function showNext() {
  return props.currentPage < props.totalPages
}

const displayUntilTimeMsg = computed(() => {
  if (!props.canNext && (!props.untilTimeInSecs || props.untilTimeInSecs === 0)) {
    return `Clique sur "C'est parti !" pour commencer !`
  } else if (props.untilTimeInSecs > 0) {
    const minutes = Math.floor(props.untilTimeInSecs / 60)
    const secondes = props.untilTimeInSecs - minutes * 60
    return `Temps restant avant de débloquer la suite : ${minutes < 10 ? '0' : ''}${minutes}:${
      secondes < 10 ? '0' : ''
    }${secondes}`
  } else {
    return `Tu peux passer à la suite !`
  }
})
</script>

<template>
  <div class="_m-PageNavigator">
    <p-button
      icon="pi pi-arrow-left"
      class="_m-PageNavigator-Button"
      v-if="showPrevious()"
      @click="handlePrevious()"
      rounded
    />
    <div class="_m-PageNavigator-Items">
      <span
        v-for="page in totalPages"
        :key="`page_${page}`"
        :class="[
          '_m-PageNavigator-Item',
          { active: page === currentPage },
          completed.includes(page) ? '_m-PageNavigator-Item--completed' : ''
        ]"
      />
    </div>
    <span
      v-if="showNext()"
      v-tooltip.top="{
        value: `${displayUntilTimeMsg}`,
        fitContent: false,
        class: 'custom-tooltip'
      }"
    >
      <p-button
        icon="pi pi-arrow-right"
        class="_m-PageNavigator-Button"
        :style="{ visibility: showNext() ? 'visible' : 'hidden' }"
        :disabled="!canNext"
        @click="handleNext()"
        rounded
      />
    </span>
  </div>
</template>

<style lang="scss">
.p-tooltip.custom-tooltip.p-tooltip-top {
  min-width: fit-content;
}

.p-tooltip.custom-tooltip.p-tooltip-top .p-tooltip-text {
  background-color: var(--primary-night-blue) !important;
  color: var(--secondary);
  text-align: left;
}

.p-tooltip.custom-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--primary-night-blue) !important;
}
</style>

<style scoped lang="scss">
._m-PageNavigator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  background-color: var(--color-primary);
  border-radius: 1rem;
  padding: 0 1rem;
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 700;
  font-family: var(--font-family);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);

  ._m-PageNavigator-Items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 1.5rem;
    position: relative;
    margin: 0 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--variant-white);
      transform: translateY(-50%);
    }

    @media screen and (max-width: 768px) {
      gap: 1rem;
    }
  }

  ._m-PageNavigator-Item {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    background-color: var(--variant-white);
    transition: all 0.3s ease-in-out;
    position: relative;

    &--completed {
      background-color: var(--primary-purple);
    }

    @media screen and (max-width: 768px) {
      width: 1rem;
      height: 1rem;
    }

    @media screen and (max-width: 768px) {
      width: 1rem;
      height: 1rem;
    }

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--variant-white);
      border: solid 0.45rem var(--primary-purple);
    }
  }

  ._m-PageNavigator-Button {
    background-color: var(--variant-white);
    color: var(--primary-dark-blue);
    border-color: var(--variant-white);

    @media screen and (max-width: 768px) {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}
</style>
