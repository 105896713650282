import { profile } from '@/services/API/profile/profile'
import { emethodos } from '@/services/API/EMethodos'
import { logbook } from '@/services/API/profile/logbook'
import { diagnostic } from '@/services/API/profile/diagnostic'
import { xAPI } from '@/services/API/xAPI'

const modules = {
  ...profile,
  ...emethodos,
  ...logbook,
  ...diagnostic,
  ...xAPI
}

export default modules
