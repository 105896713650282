import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useLocalStorage, useSessionStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import { NabooError } from '@/assets/ts/classes/Error'
import { SentryExceptionDto } from '@/assets/ts/DTO/sentry.dto'
import type { IUser } from '@/assets/ts/types/UserType'
import { useProgressStore } from '@/stores/progress'
import { v4 as uuidv4 } from 'uuid'

export const useProfileStore = defineStore('Profile', () => {
  const progressStore = useProgressStore()

  // STATE
  const me = useLocalStorage('me', {} as IUser)
  const forceDisplayMobile = useLocalStorage('forceDisplayMobile', false)
  const points = useLocalStorage('points', 0)
  const sessionUUID = useSessionStorage('sessionUUID', '')
  const displayProfileOverlay = ref(false)

  // MUTATIONS
  function setSessionUUID(uuid: string) {
    sessionUUID.value = uuid
  }

  function toggleProfile() {
    displayProfileOverlay.value = !displayProfileOverlay.value
  }

  // ACTIONS
  function initSessionUUID() {
    if (!sessionUUID.value) {
      setSessionUUID(uuidv4())
    }
  }

  function toggleForceDisplayMobile() {
    forceDisplayMobile.value = !forceDisplayMobile.value
  }

  function $reset() {
    me.value = {} as IUser
  }

  async function getMe() {
    try {
      me.value = await nabooApi.getMe()
      await progressStore.getGlobalProgress()
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      me.value = {} as IUser
      return Promise.reject(error)
    }
  }

  async function putTrackingConsentBanner() {
    try {
      me.value = await nabooApi.putTrackingConsentBanner()
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      return Promise.reject(error)
    }
  }

  // GETTERS (COMPUTED)
  const getFirstNameInitial = computed(() => {
    if (!me.value?.firstname) return ''
    return me.value.firstname?.charAt(0)
  })

  const userPoints = computed(() => points.value ?? 0)

  const shortUsername = computed(() => {
    if (!me.value?.firstname) return ''
    return `${me.value.firstname} ${me.value.lastname.charAt(0)}.`
  })

  const firstName = computed(() => me.value?.firstname ?? '')

  const userName = computed(() => {
    if (!me.value?.firstname) return ''
    return `${me.value.firstname} ${me.value.lastname}`
  })

  const hasAnsweredTrackingConsentBanner = computed(
    () => me.value?.hasAnsweredTrackingConsentBanner ?? false
  )
  const hasConsentedForTracking = computed(() => me.value?.hasConsentedForTracking ?? false)

  const userIdentifier = computed(() => me.value.identifier ?? '')

  const userPicture = computed(() => me.value.picture ?? '')

  return {
    // STATE
    me,
    forceDisplayMobile,
    sessionUUID,
    displayProfileOverlay,
    // MUTATIONS
    toggleProfile,
    // ACTIONS
    initSessionUUID,
    toggleForceDisplayMobile,
    $reset,
    getMe,
    putTrackingConsentBanner,
    // GETTERS (COMPUTED)
    shortUsername,
    getFirstNameInitial,
    userPoints,
    userName,
    firstName,
    userIdentifier,
    hasAnsweredTrackingConsentBanner,
    hasConsentedForTracking,
    userPicture
  }
})
