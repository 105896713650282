import { clearQueryParamsVersion, isFeatureEnabled, updateUrlPath } from '@/router/utils'
import { createAuthGuard } from '@auth0/auth0-vue'
import VGrain from '@/views/training/VGrain.vue'
import VContentsParent from '@/views/emethodos/VContentsParent.vue'
import VContents from '@/views/emethodos/VContents.vue'
import VEmethodo from '@/views/emethodos/VEmethodo.vue'
import VCgu from '@/views/infos/VCgu.vue'
import VQuiz from '@/views/quiz/VQuiz.vue'
import VLogbook from '@/views/logbook/VLogbook.vue'
import VMission from '@/views/training/VMission.vue'
import VOnboarding from '@/views/onboarding/VOnboarding.vue'

/**
 * Auth0 guard
 */
const authGuard = createAuthGuard()

export const routes = [
  {
    path: '',
    name: 'home',
    component: VLogbook,
    meta: {
      title: 'Mon journal de bord'
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/programme',
    name: 'program',
    children: [
      {
        path: '/mission:missionTitle?',
        name: 'mission',
        component: VMission,
        meta: {
          title: 'Mission Naboo'
        },
        beforeEnter: [authGuard, clearQueryParamsVersion, updateUrlPath]
      }
    ]
  },
  {
    path: '/planete/:grainTitle?',
    name: 'grain',
    component: VGrain,
    meta: {
      title: 'Entrainement'
    },
    beforeEnter: [authGuard, clearQueryParamsVersion, updateUrlPath]
  },
  {
    path: '/les-tutos',
    name: 'emethodos',
    component: VContentsParent,
    meta: {
      title: 'Contenus'
    },
    children: [
      {
        path: '',
        name: 'contents',
        component: VContents,
        meta: {
          title: 'Contenus'
        },
        beforeEnter: [clearQueryParamsVersion, isFeatureEnabled]
      },
      {
        path: './:videoTitle?',
        name: 'video',
        component: VEmethodo,
        meta: {
          title: 'Contenus'
        },
        beforeEnter: [clearQueryParamsVersion, updateUrlPath, isFeatureEnabled]
      }
    ],
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/cgu',
    name: 'terms-of-use',
    component: VCgu,
    meta: {
      title: "Conditions générales d'utilisation",
      hideHeader: true
    }
  },
  {
    path: '/test-d-entree',
    name: 'diagnostic',
    component: VQuiz,
    meta: {
      title: "Test d'entrée de la mission Naboo",
      hideHeader: true,
      hideBottomMenu: true
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/bienvenue',
    name: 'welcome',
    component: VOnboarding,
    meta: {
      title: 'Bienvenue',
      hideHeader: true,
      hideBottomMenu: true
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  }
]
