import type { IProgressQuestion, IQuiz } from '@/assets/ts/types/progress/Quiz'
import { DateTime } from 'ts-luxon'

export class Quiz implements IQuiz {
  quizId: number
  quizType: string
  moduleTitle: string
  moduleName: string
  courseId: number
  moduleId: number | null
  score: number | null
  scoreMin: number
  scoreMax: number
  scaled: number | null
  duration: {
    seconds: number | null
    iso8601: string | null
  }
  startTime: string | null // Assuming ISO 8601 date-time string
  endTime: string | null // Assuming ISO 8601 date-time string
  uuidAttemptSession: string | null
  questions: IProgressQuestion[]
  isCompleted: boolean

  constructor(data: IQuiz) {
    this.quizId = data.quizId
    this.quizType = data.quizType
    this.moduleTitle = data.moduleTitle
    this.moduleName = data.moduleName
    this.courseId = data.courseId
    this.moduleId = data.moduleId
    this.score = data.score
    this.scoreMin = data.scoreMin ?? 0 // Example: set a minimum score
    this.scoreMax = data.scoreMax ?? data.questions.length // Example: set a maximum score
    this.scaled = data.scaled
    this.startTime = data.startTime
    this.endTime = data.endTime
    this.uuidAttemptSession = data.uuidAttemptSession
    this.questions = data.questions
      .sort((a, b) => a.order - b.order)
      .map((question) => {
        question.scoreMin = question.scoreMin ?? 0 // Example: set a minimum score
        question.scoreMax = question.scoreMax ?? 1 // Example: set a maximum score
        return question
      })
    this.isCompleted = data.isCompleted
    this.duration = {
      seconds: data.duration.seconds,
      iso8601: data.duration.iso8601
    }
  }

  get formattedStartTime() {
    return DateTime.fromISO(<string>this.startTime).toLocaleString(DateTime.DATETIME_FULL)
  }

  get formattedEndTime() {
    return DateTime.fromISO(<string>this.endTime).toLocaleString(DateTime.DATETIME_FULL)
  }

  get formattedDuration() {
    return DateTime.fromISO(<string>this.duration.iso8601).toFormat('hh:mm:ss')
  }

  get lastUnansweredQuestion() {
    return this.questions.find((question) => !question.isAnswered)
  }

  get isQuizStarted() {
    return this.startTime !== null
  }

  get unansweredQuestions() {
    return this.questions.filter((question) => !question.isAnswered)
  }

  startQuiz(timestamp: string, attemptSessionUUID: string) {
    this.startTime = timestamp
    this.uuidAttemptSession = attemptSessionUUID
  }

  answerQuestion(questionId: number, score: number, uuidQuestion: string) {
    const questionIndex = this.questions.findIndex((question) => question.questionId === questionId)

    if (questionIndex !== -1) {
      this.questions[questionIndex].isAnswered = true
      this.questions[questionIndex].score = score
      this.questions[questionIndex].uuidQuestion = uuidQuestion
      this.questions[questionIndex].scoreMin = 0
      this.questions[questionIndex].scoreMax = 1
      this.questions[questionIndex].scaled = score
    }
  }

  completeQuiz() {
    this.endTime = new Date().toISOString()
    this.duration.seconds = DateTime.fromISO(<string>this.endTime).diff(
      DateTime.fromISO(<string>this.startTime),
      'seconds'
    ).seconds
    this.duration.iso8601 = DateTime.fromISO(<string>this.endTime)
      .diff(DateTime.fromISO(<string>this.startTime))
      .toISO()
    this.score = this.questions.reduce((acc, question) => acc + (question.score || 0), 0)
    this.scoreMin = 0
    this.scoreMax = this.questions.length
    this.scaled = (this.score - this.scoreMin) / (this.scoreMax - this.scoreMin)
    this.isCompleted = true
  }
}
