import { Quiz } from '@/assets/ts/classes/progress/quiz'
import type { DiagnosticResponseDto } from '@/assets/ts/DTO/quiz/diagnostic.response.dto'

export class Diagnostic {
  quizzes: Quiz[]
  explorationName: string

  constructor(data: DiagnosticResponseDto) {
    this.quizzes = data.quizzes.map((quiz) => new Quiz(quiz))
    this.explorationName = data.quizzes[0].moduleName
  }

  get quizzesToAttempt() {
    return this.quizzes.filter((quiz) => !quiz.isCompleted && quiz.questions.length > 0)
  }

  get isStarted() {
    return this.quizzes.some((quiz) => quiz.isQuizStarted)
  }

  get quizInProgress() {
    return this.quizzes.find((quiz) => quiz.isQuizStarted && !quiz.isCompleted)
  }

  get quizzesToAtemptCount() {
    return this.quizzesToAttempt.length
  }
}
