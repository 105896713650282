<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import MascotteIntro from '@/assets/images/mascottes/MASCOTTE_Accueil.png'
import MascotteQuizThemeUrl from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_theme.png'
import { default as PButton } from 'primevue/button'

import QuizHeader from '@/views/quiz/blocks/QuizHeader.vue'
import { onMounted, ref, useCssModule } from 'vue'
import QuizIntro from '@/views/quiz/blocks/QuizIntro.vue'
import { useQuizStore } from '@/stores/quiz'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import type { Quiz } from '@/assets/ts/classes/progress/quiz'
import QuizThemeIntro from '@/views/quiz/blocks/QuizThemeIntro.vue'
import QuizContent from '@/views/quiz/blocks/QuizContent.vue'
import QuizzesResults from '@/views/quiz/blocks/QuizzesResults.vue'

// VARIABLES
const loading = ref(true)
const expandedHeader = ref(true)
const showIntro = ref(false)
const style = useCssModule()
const route = useRoute()
const { diagnostic } = storeToRefs(useQuizStore())
const quizToAnswer = ref({} as Quiz)
const showThemeIntro = ref(false)
const showDiagResults = ref(false)

// HOOKS
onMounted(async () => {
  await getQuizFromCourseSlug(route.query.exploration as string)

  if (diagnostic.value.quizzesToAttempt.length === 0) {
    expandedHeader.value = false
    showDiagResults.value = true
    loading.value = false
    return
  }

  if (diagnostic.value.isStarted) {
    expandedHeader.value = false
    showIntro.value = false
    quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
    if (!quizToAnswer.value.isQuizStarted) {
      showThemeIntro.value = true
    }
  } else {
    expandedHeader.value = true
    setTimeout(() => {
      expandedHeader.value = false

      setTimeout(() => {
        showIntro.value = true
      }, 300)
    }, 500)
  }
  loading.value = false
})

// METHODS
const { getQuizFromCourseSlug, startDiagnostic } = useQuizStore()

const start = () => {
  showIntro.value = false
  showThemeIntro.value = true
  quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
}

const startDiagnosticQuiz = (quiz: Quiz) => {
  startDiagnostic(quiz)
  showThemeIntro.value = false
}

const handleEndQuiz = () => {
  if (diagnostic.value.quizzesToAtemptCount > 0) {
    quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
    showThemeIntro.value = true
  } else {
    showDiagResults.value = true
    console.log('End of diagnostic')
  }
}
</script>

<template>
  <div class="h-full w-full flex flex-col overflow-auto" :class="$style['VQuiz']" v-if="!loading">
    <quiz-header title="Test d'entrée" :expanded="expandedHeader" />

    <quiz-intro v-if="showIntro" @start="start" />

    <quiz-theme-intro
      :theme-title="quizToAnswer.moduleTitle"
      :questions-count="quizToAnswer.questions.length"
      v-if="showThemeIntro"
      @start="startDiagnosticQuiz(quizToAnswer)"
    />

    <quiz-content
      v-if="quizToAnswer.isQuizStarted && !showIntro && !showThemeIntro && !showDiagResults"
      :quiz="quizToAnswer"
      :diagnostic="diagnostic"
      @end-quiz="handleEndQuiz()"
    />

    <quizzes-results v-if="showDiagResults" />
  </div>
</template>

<style module lang="scss">
.VQuiz {
  background: var(--linear-02);
  position: relative;
}
</style>
