<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import EIcon from '@/components/elements/icons/EIcon.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import { computed } from 'vue'

const props = defineProps<{
  size?: 'large'
  action?: () => void
}>()

const classes = computed(() => {
  return {
    'w-8 h-8': !props.size,
    'w-12 h-12': props.size === 'large'
  }
})
</script>

<template>
  <e-icons :load="['QuestionMark']" />

  <p-button
    class="rounded-full inline-block p-1 shadow-lg"
    :class="classes"
    severity="linear"
    text
    @click="action"
  >
    <e-icon name="QuestionMark" type="svg" class="w-4" style="--fill: var(--variant-white)" />
  </p-button>
</template>
