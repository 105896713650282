<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Quiz } from '@/assets/ts/classes/progress/quiz'
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import MultipleChoiceAnswers from '@/views/quiz/blocks/answers/MultipleChoiceAnswers.vue'
import type { IMCQQuestion, IQuestion } from '@/assets/ts/types/learning/QuizQuestion'
import { useQuizStore } from '@/stores/quiz'
import type { Diagnostic } from '@/assets/ts/classes/progress/diagnostic'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import SingleChoiceAnswer from '@/views/quiz/blocks/answers/SingleChoiceAnswer.vue'
import TrueFalseAnswers from '@/views/quiz/blocks/answers/TrueFalseAnswers.vue'
import MatchingAnswers from '@/views/quiz/blocks/answers/MatchingAnswers.vue'
import OverlayQuestionAnswered from '@/views/quiz/blocks/overlays/OverlayQuestionAnswered.vue'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'
import SequencingAnswers from '@/views/quiz/blocks/answers/SequencingAnswers.vue'
import OverlayNeedHelp from '@/views/quiz/blocks/overlays/OverlayNeedHelp.vue'
import EHelpButton from '@/components/elements/buttons/EHelpButton.vue'
import { Duration } from 'ts-luxon'

const emit = defineEmits(['EndQuiz'])

const props = defineProps<{
  quiz: Quiz
  diagnostic: Diagnostic
}>()
const questionToAnswer = ref({} as IQuestion)
const showOverlay = ref(false)
const answerIsCorrect = ref(false)
const loadingQuestion = ref(false)
const showHelp = ref(false)
const answerDuration = ref(0)

onMounted(() => {
  questionToAnswer.value = props.quiz.lastUnansweredQuestion
  answerDuration.value = Date.now()
})

const answerComponent = computed(() => {
  switch (questionToAnswer.value.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return MultipleChoiceAnswers
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return SingleChoiceAnswer
    case LearningEnums.QuestionType.TRUE_FALSE:
      return TrueFalseAnswers
    case LearningEnums.QuestionType.MATCHING:
      return MatchingAnswers
    case LearningEnums.QuestionType.SEQUENCING:
      return SequencingAnswers
    default:
      return MultipleChoiceAnswers
  }
})

const { sendAnsweredQuestion, sendCompletedQuiz } = useQuizStore()

const handleAnswer = async (userAnswer: IUserAnswer) => {
  answerIsCorrect.value = userAnswer.isCorrect
  answerDuration.value = Math.round((Date.now() - answerDuration.value) / 1000)
  const duration = Duration.fromObject({ seconds: answerDuration.value }).toISO() as string
  await sendAnsweredQuestion(props.quiz, questionToAnswer.value, userAnswer, duration)

  showOverlay.value = true

  setTimeout(() => {
    showOverlay.value = false
    loadingQuestion.value = true
    console.log(props.quiz.lastUnansweredQuestion)

    if (props.quiz.lastUnansweredQuestion) {
      questionToAnswer.value = props.quiz.lastUnansweredQuestion
      answerDuration.value = Date.now()
    } else {
      handleQuizComplete()
    }

    loadingQuestion.value = false
  }, 2000)
}

const handleQuizComplete = () => {
  props.quiz.completeQuiz()
  sendCompletedQuiz(props.quiz)
  emit('EndQuiz')
}
</script>

<template>
  <div class="flex justify-between items-center px-4 py-2 bg-white">
    <p class="text-primary">{{ quiz.moduleTitle }}</p>
    <div class="flex gap-2">
      <div
        v-for="quiz in diagnostic.quizzesToAtemptCount"
        :key="quiz"
        class="rounded-full h-3 w-3 bg-surface-blue-500"
      />
    </div>
  </div>
  <e-progress-bar
    :max="quiz.questions.length"
    :value="quiz.questions.length - quiz.unansweredQuestions.length + 1"
    :rounded="false"
    :colors="{ progress: 'var(--variant-pink)' }"
  />
  <div
    class="flex flex-col items-center justify-start bg-surface-blue-500 flex-grow px-4 pt-6 pb-24"
  >
    <div class="w-full flex-col items-center mb-6 max-w-72">
      <p class="font-bold text-center">{{ questionToAnswer.label }}</p>
      <p
        v-if="questionToAnswer.type === LearningEnums.QuestionType.MULTIPLE_CHOICE"
        class="italic text-center"
      >
        (Plusieurs réponses possibles)
      </p>
    </div>

    <component
      v-if="!loadingQuestion"
      :is="answerComponent"
      :answers-content="questionToAnswer.answers"
      @answer="(answer: IUserAnswer) => handleAnswer(answer)"
    />

    <div class="fixed bottom-4 right-4">
      <e-help-button :action="() => (showHelp = true)" size="large" />
    </div>
  </div>

  <transition>
    <overlay-question-answered v-if="showOverlay" :correct="answerIsCorrect" />
  </transition>
  <overlay-need-help v-if="showHelp" :type="questionToAnswer.type" @close="showHelp = !showHelp" />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
