import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import type { ISession } from '@/assets/ts/types/TrainingTypes'
import nabooApi from '@/services/nabooApi'
import { useProfileStore } from '@/stores/profile'
import { useLocalStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import { useAppStore } from '@/stores/app'
import { updateCurrentUrlPath } from '@/router/utils'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import type { IRating } from '@/assets/ts/types/ratingTypes'
import {
  TrainingGrainInputDto,
  TrainingRatingInputDto,
  TrainingSessionInputDto,
  TrainingSupportInputDto,
  VerbsDisplayEnum,
  VerbsIdEnum
} from '@/assets/ts/DTO/trainings/trainings.dto'

export const useSessionsStore = defineStore('sessions', () => {
  const profileStore = useProfileStore()
  const authStore = useAuthStore()
  const router = useRouter()
  const route = useRoute()
  const { registrationCtx, appType, userAgent } = storeToRefs(useAppStore())

  // STATE
  const trainingId = ref(1)
  const loading = ref(true)
  const trainingSessions = useLocalStorage('training-sessions', [] as ISession[])
  const currentCompletingTrainingSessionId = useLocalStorage(
    'currentCompletingTrainingSessionId',
    0
  )
  const currentCompletingTrainingUnitId = useLocalStorage('currentCompletingTrainingUnitId', 0)
  const currentCompletingTrainingGrainId = useLocalStorage('currentCompletingTrainingGrainId', 0)
  const currentCompletingTrainingSupportId = useLocalStorage(
    'currentCompletingTrainingSupportId',
    0
  )

  // GETTERS
  const countTrainingSession = computed(() => {
    return trainingSessions.value.length
  })

  const trainingUnits = computed(() =>
    trainingSessions.value.flatMap((session) => session.trainingUnits)
  )

  const trainingGrains = computed(() => trainingUnits.value.flatMap((unit) => unit.trainingGrains))

  const trainingSupports = computed(() =>
    trainingGrains.value.flatMap((grain) => grain.trainingSupports)
  )

  const currentCompletingTrainingSession = computed(() => {
    return trainingSessions.value?.find(
      (session) => session.trainingSessionId === currentCompletingTrainingSessionId.value
    )
  })

  const currentCompletingTrainingUnit = computed(() => {
    return trainingUnits.value?.find(
      (unit) => unit.trainingUnitId === currentCompletingTrainingUnitId.value
    )
  })

  const currentCompletingTrainingGrain = computed(() => {
    return trainingGrains.value?.find(
      (grain) => grain.trainingGrainId === currentCompletingTrainingGrainId.value
    )
  })

  const currentCompletingTrainingSupport = computed(() => {
    return trainingSupports.value?.find(
      (support) => support.id === currentCompletingTrainingSupportId.value
    )
  })

  const currentGrainSupportsWithEnd = computed(() => {
    // Return the current grain supports with the end support
    if (currentCompletingTrainingGrainId.value === 0) return []

    if (!currentCompletingTrainingGrain.value) return []

    return currentCompletingTrainingGrain.value.trainingSupports
  })

  const currentSupportIsLast = computed(() => {
    if (currentCompletingTrainingSupportId.value === 0) return true
    const currentSupport = currentCompletingTrainingSupport.value
    const supports = currentGrainSupportsWithEnd.value

    if (!currentSupport) return true

    return currentSupport.order === supports.length
  })

  const currentGrainIsLast = computed(() => {
    if (currentCompletingTrainingUnitId.value === 0 || currentCompletingTrainingGrainId.value === 0)
      return true
    const currentGrain = currentCompletingTrainingGrain.value

    if (!currentGrain) return true
    if (!currentCompletingTrainingUnit.value) return true

    const grains = currentCompletingTrainingUnit.value.trainingGrains
    return currentGrain.order === grains.length
  })

  const hasNextTrainingSession = computed(() => {
    if (currentCompletingTrainingSessionId.value === 0) return false
    const currentSession = currentCompletingTrainingSession.value

    if (!currentSession) return false

    const nextSession = trainingSessions.value.find((session) => {
      return session.order === currentSession.order + 1
    })
    return nextSession ?? false
  })

  const hasNextTrainingGrain = computed(() => {
    if (currentCompletingTrainingUnitId.value === 0 || currentCompletingTrainingGrainId.value === 0)
      return false
    const currentGrain = currentCompletingTrainingGrain.value

    if (!currentGrain) return false
    if (!currentCompletingTrainingUnit.value) return false

    const nextGrain = currentCompletingTrainingUnit.value.trainingGrains.find(
      (grain) => grain.order === currentGrain.order + 1
    )
    return nextGrain ?? false
  })

  const hasNextTrainingSupport = computed(() => {
    if (
      currentCompletingTrainingGrainId.value === 0 ||
      currentCompletingTrainingSupportId.value === 0
    )
      return false

    if (!currentCompletingTrainingGrain.value) return false
    if (!currentCompletingTrainingUnit.value) return false

    const currentSupport = currentCompletingTrainingSupport.value

    if (!currentSupport) return false

    const nextSupport = currentCompletingTrainingGrain.value.trainingSupports.find(
      (support) => support.order === currentSupport.order + 1
    )
    return nextSupport ?? false
  })

  const currentTrainingSession = computed(() => {
    return (
      trainingSessions.value.find((session) => session.current) ??
      trainingSessions.value[trainingSessions.value.length - 1]
    )
  })

  // ACTIONS
  function $reset() {
    trainingId.value = 1
    loading.value = false
    trainingSessions.value = [] as ISession[]
    currentCompletingTrainingSessionId.value = 0
    currentCompletingTrainingUnitId.value = 0
    currentCompletingTrainingGrainId.value = 0
    currentCompletingTrainingSupportId.value = 0
  }

  async function getTrainingSessions() {
    try {
      loading.value = true
      const data = await nabooApi.getTrainingProgress(trainingId.value)

      trainingSessions.value = data.trainingSessions
      currentCompletingTrainingSessionId.value = currentTrainingSession.value.trainingSessionId
      updateCurrentUrlPath(router, route)
      loading.value = false
    } catch (error) {
      trainingSessions.value = []
      Sentry.captureException(error)
    }
  }

  // ACTIONS - xAPI
  async function sendxAPIMessage(
    input:
      | TrainingRatingInputDto
      | TrainingSessionInputDto
      | TrainingGrainInputDto
      | TrainingSupportInputDto
  ) {
    if (authStore.isStudent) await nabooApi.sendTrainingxApiMessage(input)
  }

  async function startTrainingSession(sessionId: number, unitId: number = 0) {
    const trainingSession = trainingSessions.value.find(
      (session) => session.trainingSessionId === sessionId
    )

    if (!trainingSession) return
    currentCompletingTrainingSessionId.value = sessionId

    const input = new TrainingSessionInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      sessionId: sessionId,
      sessionTitle: trainingSession.title,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      unitId: unitId,
      verbId: VerbsIdEnum.Started,
      verbDisplay: VerbsDisplayEnum.Started
    })

    await sendxAPIMessage(input)

    trainingSession.started = true
    const unit =
      trainingSession.trainingUnits.find((unit) => unit.type === 'ASYNCHRONE') ??
      trainingSession.trainingUnits[0]
    unit.trainingGrains[0].canStart = true
  }

  async function startTrainingGrain(sessionId: number, grainId: number, unitId: number) {
    const trainingGrain = trainingGrains.value.find((grain) => grain.trainingGrainId === grainId)
    const session = trainingSessions.value.find(
      (session) => session.trainingSessionId === sessionId
    )

    // @TODO - Generate error message
    if (!session) return

    if (!trainingGrain) return

    currentCompletingTrainingSessionId.value = sessionId
    currentCompletingTrainingUnitId.value = unitId
    currentCompletingTrainingGrainId.value = grainId

    const input = new TrainingGrainInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      grainId: grainId,
      grainTitle: trainingGrain.title,
      points: trainingGrain.points,
      unitId: unitId,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      parent: session,
      verbId: VerbsIdEnum.Started,
      verbDisplay: VerbsDisplayEnum.Started
    })

    await sendxAPIMessage(input)

    trainingGrain.started = true
    const nextSupport = trainingGrain.trainingSupports.find(
      (support) =>
        (!support.started && !support.completed) || (support.started && !support.completed)
    )
    currentCompletingTrainingSupportId.value =
      nextSupport?.id ?? trainingGrain.trainingSupports[0].id
  }

  async function startTrainingSupport(supportId: number, unitId: number) {
    const trainingSupport = trainingSupports.value.find(
      (support) => support.id === supportId && support.unitId === unitId
    )

    // @TODO - Generate error message
    if (!trainingSupport) return
    if (!currentCompletingTrainingGrain.value) return

    currentCompletingTrainingUnitId.value = unitId
    currentCompletingTrainingSupportId.value = supportId

    const input = new TrainingSupportInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      supportId: supportId,
      supportTitle: trainingSupport.url,
      unitId: unitId,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      parent: currentCompletingTrainingGrain.value,
      verbId: VerbsIdEnum.Started,
      verbDisplay: VerbsDisplayEnum.Started
    })

    await sendxAPIMessage(input)

    trainingSupport.started = true
  }

  async function completeTrainingSupport(supportId: number, unitId: number) {
    const trainingSupport = trainingSupports.value.find(
      (support) => support.id === supportId && support.unitId === unitId
    )

    // @TODO - Generate error message
    if (!trainingSupport) return
    if (!currentCompletingTrainingGrain.value) return

    currentCompletingTrainingSupportId.value = supportId

    const input = new TrainingSupportInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      supportId: supportId,
      supportTitle: trainingSupport.url,
      unitId: unitId,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      parent: currentCompletingTrainingGrain.value,
      verbId: VerbsIdEnum.Completed,
      verbDisplay: VerbsDisplayEnum.Completed
    })

    await sendxAPIMessage(input)

    trainingSupport.completed = true
  }

  async function completeTrainingGrain(grainId: number, unitId: number) {
    const trainingGrain = trainingGrains.value.find((grain) => grain.trainingGrainId === grainId)

    // @TODO - Generate error message
    if (!trainingGrain) return
    if (!currentCompletingTrainingSession.value) return

    currentCompletingTrainingGrainId.value = grainId
    currentCompletingTrainingUnitId.value = unitId

    const input = new TrainingGrainInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      grainId: grainId,
      grainTitle: trainingGrain.title,
      points: trainingGrain.completed ? 0 : trainingGrain.points,
      unitId: unitId,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      parent: currentCompletingTrainingSession.value,
      verbId: VerbsIdEnum.Completed,
      verbDisplay: VerbsDisplayEnum.Completed
    })

    await sendxAPIMessage(input)

    trainingGrain.completed = true
    const nextGrain = hasNextTrainingGrain.value
    if (nextGrain) nextGrain.canStart = true
    await profileStore.getPoints()
  }

  async function completeTrainingSession(sessionId: number, unitId: number) {
    const trainingSession = trainingSessions.value.find(
      (session) => session.trainingSessionId === sessionId
    )

    if (!trainingSession) return
    currentCompletingTrainingSessionId.value = sessionId

    const input = new TrainingSessionInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      sessionId: sessionId,
      sessionTitle: trainingSession.title,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      unitId: unitId,
      verbId: VerbsIdEnum.Completed,
      verbDisplay: VerbsDisplayEnum.Completed
    })

    await sendxAPIMessage(input)

    trainingSession.completed = true
    trainingSession.current = false
    const nextSession = hasNextTrainingSession.value
    if (nextSession && currentCompletingTrainingSession.value) {
      currentCompletingTrainingSession.value.current = true
    }
    await getTrainingSessions()
  }

  async function sendRating(grainId: number, unitId: number, ratings: IRating[], review: string) {
    const trainingGrain = trainingGrains.value.find((grain) => grain.trainingGrainId === grainId)

    if (!trainingGrain) return
    if (!currentCompletingTrainingSession.value) return

    const input = new TrainingRatingInputDto({
      trainingId: trainingId.value,
      profile: { identifier: profileStore.userIdentifier, userName: profileStore.userName },
      grainId: grainId,
      grainTitle: trainingGrain.title,
      unitId: unitId,
      registrationCtx: registrationCtx.value,
      userAgent: userAgent.value,
      appType: appType.value,
      parent: currentCompletingTrainingSession.value,
      ratings: ratings,
      review: review,
      verbId: VerbsIdEnum.Reviewed,
      verbDisplay: VerbsDisplayEnum.Reviewed
    })

    await sendxAPIMessage(input)
  }

  function displayCurrentTrainingSession() {
    if (currentTrainingSession.value) {
      currentCompletingTrainingSessionId.value = currentTrainingSession.value.trainingSessionId
      updateCurrentUrlPath(router, route)
    }
  }

  function previousTrainingSession() {
    const currentSession = currentCompletingTrainingSession.value

    // Check if currentSession is defined and its order is greater than 0
    if (!currentSession || currentSession.order <= 0) return

    const previousSessionOrder = currentSession.order - 1
    const previousSession = trainingSessions.value.find(
      (session) => session.order === previousSessionOrder
    )

    // Check if previousSession is found
    if (!previousSession) return

    currentCompletingTrainingSessionId.value = previousSession.trainingSessionId
    updateCurrentUrlPath(router, route)
  }

  function nextTrainingSession() {
    const currentSession = currentCompletingTrainingSession.value

    // Check if currentSession is defined and its order is less than the length of trainingSessions
    if (!currentSession || currentSession.order >= trainingSessions.value.length - 1) return

    const nextSessionOrder = currentSession.order + 1
    const nextSession = trainingSessions.value.find((session) => session.order === nextSessionOrder)

    // Check if nextSession is found
    if (!nextSession) return

    currentCompletingTrainingSessionId.value = nextSession.trainingSessionId
    updateCurrentUrlPath(router, route)
  }

  function previousTrainingSupport() {
    const currentSupport = currentCompletingTrainingSupport.value

    // Check if currentSupport is defined and its order is greater than 1
    if (!currentSupport || currentSupport.order <= 1) return

    const previousSupportOrder = currentSupport.order - 1
    const previousSupport = currentGrainSupportsWithEnd.value.find(
      (support) => support.order === previousSupportOrder
    )

    // Check if previousSupport is found
    if (!previousSupport) return

    currentCompletingTrainingSupportId.value = previousSupport.id
    updateCurrentUrlPath(router, route)
  }

  function nextTrainingSupport() {
    const currentSupport = currentCompletingTrainingSupport.value

    // Check if currentSupport is defined and its order is less than the length of currentGrainSupportsWithEnd
    if (!currentSupport || currentSupport.order >= currentGrainSupportsWithEnd.value.length - 1)
      return

    const nextSupportOrder = currentSupport.order + 1
    const nextSupport = currentGrainSupportsWithEnd.value.find(
      (support) => support.order === nextSupportOrder
    )

    // Check if nextSupport is found
    if (!nextSupport) return

    currentCompletingTrainingSupportId.value = nextSupport.id
    updateCurrentUrlPath(router, route)
  }

  return {
    // STATE
    loading,
    trainingSessions,
    currentCompletingTrainingSessionId,
    currentCompletingTrainingGrainId,
    currentCompletingTrainingUnitId,
    currentCompletingTrainingSupportId,
    // GETTERS
    countTrainingSession,
    trainingUnits,
    trainingGrains,
    trainingSupports,
    currentCompletingTrainingSession,
    currentCompletingTrainingGrain,
    currentCompletingTrainingUnit,
    currentCompletingTrainingSupport,
    currentGrainSupportsWithEnd,
    currentSupportIsLast,
    currentGrainIsLast,
    hasNextTrainingSession,
    hasNextTrainingGrain,
    hasNextTrainingSupport,
    currentTrainingSession,
    // ACTIONS
    $reset,
    getTrainingSessions,
    dislpayCurrentTrainingSession: displayCurrentTrainingSession,
    // ACTIONS - xAPI
    startTrainingSession,
    startTrainingGrain,
    startTrainingSupport,
    completeTrainingSession,
    completeTrainingGrain,
    completeTrainingSupport,
    previousTrainingSession,
    nextTrainingSession,
    previousTrainingSupport,
    nextTrainingSupport,
    sendRating
  }
})
