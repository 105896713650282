import { defineStore } from 'pinia'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/ts/classes/Error'
import { type Ref, ref } from 'vue'
import type {
  IGlobalProgress,
  IProgressMission,
  IProgressQuiz
} from '@/assets/ts/types/progress/Progress'

export const useProgressStore = defineStore('progress', () => {
  // STATE
  const globalProgress: Ref<IGlobalProgress> = ref({} as IGlobalProgress)
  const testsToPass: Ref<IProgressQuiz[]> = ref([])
  const missionsInProgress: Ref<IProgressMission[]> = ref([])

  // ACTIONS
  async function getGlobalProgress() {
    try {
      const progress = await nabooApi.getLogbookInfos()
      globalProgress.value = progress.general
      testsToPass.value = progress.quiz || []
      missionsInProgress.value = progress.missions || []
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      console.error(err)
    }
  }

  // RETURN
  return {
    globalProgress,
    testsToPass,
    missionsInProgress,
    getGlobalProgress
  }
})
